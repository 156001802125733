import React from 'react';
import DatePicker from 'react-datepicker';

import AbstractField from './AbstractField';
import OsField from './';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { MaskField } from 'react-mask-field';

import 'react-datepicker/dist/react-datepicker.css';

export default class OsDateField extends AbstractField {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  onChange = (date) => {
    this.props.input.onChange && this.props.input.onChange(date);
    this.props.onChange && this.props.onChange(date);
  };

  onSelect = (date) => {
    this.props.onCalendarClose && this.props.onCalendarClose(date);
  };

  onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      this.myRef.current.setOpen(false);
    }
  };

  render() {
    let minDate = this.props.pastDateSelectable ? null : new Date(),
      maxDate = this.props.futureDateSelectable ? null : new Date(),
      props = this.getFieldProps(),
      value =
        this.props.input.value instanceof Date
          ? this.props.input.value
          : props.selected;

    return (
      <>
        <OsField {...this.props} osType='label' isWrapperRequired={false} />
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          {...props}
          selected={value}
          placeholderText={this.props.placeholderText}
          onSelect={this.onSelect}
          onBlur={() => this.onSelect(value)}
          onChange={this.onChange}
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={100}
          onKeyDown={this.onKeyDown}
          ref={this.myRef}
          customInput={
            <MaskField
              mask='mm/dd/yyyy'
              replacement={{ d: /\d/, m: /\d/, y: /\d/ }}
              separate
            />
          }
        />
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
        {this.renderChildren()}
      </>
    );
  }
}

OsDateField.defaultProps = {
  meta: {},
  input: {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  saveInputRef: () => {},
  placeholderText: 'MM/DD/YYYY',
  pastDateSelectable: false,
  futureDateSelectable: true,
};
