import { useEffect, useRef, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { ceil, now } from 'lodash';
import { useSelector } from 'react-redux';
import storeUpdater from 'app/services/StoreUpdater';
import { useDispatch } from 'react-redux';
import { commentShareConsume } from 'app/actions/commentShare';

import { useLocalStorage } from 'app/hooks/useLocalStorage';
import debounce from 'lodash/debounce';
import { changeMentioneeNameWithId } from 'app/utils/TiptapEditor/editorHelper';
import { PARTNERS_SPACE_LISTING } from 'app/components/Partners/usePartnersListing';

const WRITE_COMMENT_MUTATION = queryLoader(
  'app/graphql/mutations/PartnerSpaces/PartnerActivityComment.gql',
);

const usePartnerSpaceEditor = (space) => {
  const id = space?.nice_id;
  const [files, handleFiles] = useState([]);
  const [objects, handleObjects] = useState([]);
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const [labelDropdownEntity, setLabelDropdownEntity] = useState(null);
  const [localStoreContent, setLocalStorageContent] = useLocalStorage(
    `partnerSpaceEditor-${id}`,
    '',
  );
  const dispatch = useDispatch();

  const [writeComment] = useMutation(WRITE_COMMENT_MUTATION);
  const editorRef = useRef(null);
  const editorDataRef = useRef({
    initialContent: '',
    content: '',
  });

  const handleEditorVisibility = (visibility) => {
    if (visibility) {
      setIsEditorVisible(visibility);
    } else {
      setIsEditorVisible((prevState) => !prevState);
    }
  };

  const handleLabelDropdown = (event, entity) => {
    setLabelDropdownEntity(entity);
  };

  const saveContentInLocalStorage = debounce(() => {
    setLocalStorageContent(editorDataRef.current.content);
  }, 300);

  const updateContent = (content) => {
    editorDataRef.current.content = content;
    saveContentInLocalStorage();
  };

  const handleTemplate = (template) => {
    if (editorRef.current) {
      editorRef.current.editorFunction('template', { template: template });
      handleFiles(template.files);
      handleObjects(template.links);
    }
  };
  const postComment = async () => {
    let variables = {
      commentableId: id, // niceId
      commentableType: 'Board', // either Board or comment
      content: changeMentioneeNameWithId(editorDataRef.current.content),
      files: files.map((file) => file.id),
      links: objects,
      parentId: null,
      attachEntities: labelDropdownEntity
        ? [`${labelDropdownEntity.__typename}:${labelDropdownEntity.id}`]
        : [],
    };

    let optimisticResponseEntity = {
      commentable_id: space.id,
      parent_id: null,
      pinnable: true,
      pinned: false,
      pinned_at: null,
      processed: false,
      type: 'Discussion',
      linked_entities: [labelDropdownEntity],
      secured: true,
      commentable_name: '',
      commentable_path: 'commentable_path',
      commentable_type: 'Board',
      content: editorDataRef.current.content,
      created_at: ceil(now() / 1000),
      entity_path: '',
      frontend_url: '',
      hash_tags: [],
      id: '-1',
      nice_id: '',
      replies_count: 0,
      updated_at: ceil(now() / 1000),
      author: currentUser.graph,
      mentionees: [],
      mention_groups: [],
      viewed_by: [],
      view_count: 0,
      emoji_reaction_detail: {
        id: now(),
        emoji_reaction_users: [],
        __typename: 'EmojiReactionDetail',
      },
      links: [],
      files: [],
      visible_audience_ids: [],
      additional_replies: [],
      images_and_videos: [],
      commentable: {
        __typename: 'PartnerSpace',
      },
      __typename: 'Comment',
    };

    updateCommentList(optimisticResponseEntity);

    try {
      setIsRequestProcessing(true);
      await writeComment({
        variables: variables,
        optimisticResponse: {
          writeComment: {
            success: true,
            errors: null,
            entity: optimisticResponseEntity,
          },
        },
        refetchQueries: [PARTNERS_SPACE_LISTING],
        update: (cache, { data }) => {
          updateCommentList(
            data.writeComment.entity,
            data.writeComment.secondary_entity,
          );
          setLocalStorageContent('');
          setLabelDropdownEntity(null);
          handleFiles([]);
          handleObjects([]);
          dispatch(commentShareConsume());
          setIsEditorVisible(false);
        },
        onCompleted: () => {},
      });
    } catch (error) {
      console.log('error: ', error.message);
    }
    setIsRequestProcessing(false);
  };

  const updateCommentList = (comment, activity = {}) => {
    let commentActivity = {
      action: null,
      authors: [comment.author],
      effect_activity: null,
      entities: [
        {
          id: space.id,
          feed_name: space.feed_name,
          frontend_url: space.frontend_url,
          nice_id: space.nice_id,
          type: 'Partner',
          __typename: 'PartnerSpace',
        },
      ],
      id: comment.id === '-1' ? -1 : activity.id || comment.id,
      objects: [comment],
      source_type: 'SpaceCommentActivity',
      template_text: 'You posted in %{[link:nice_id]entity.feed_name}',
      visible_at: now(),
      __typename: 'Activity',
    };
    storeUpdater.addDataInRecordPager(commentActivity, {
      limit: 10,
      recordId: space.id,
      recordType: 'Board',
      id_query: space.id,
      type: 'PartnerSpaceActivities',
      queryType: 'PARTNER_COMMENTS_ACTIVITY_LISTING',
      idToBeRemoved: comment.id === '-1' ? null : -1,
    });
  };

  useEffect(() => {
    if (localStoreContent) {
      editorDataRef.current = {
        ...editorDataRef.current,
        initialContent: localStoreContent,
        content: localStoreContent,
      };
    }
  }, []);

  return {
    files,
    handleFiles,
    objects,
    handleObjects,
    isEditorVisible,
    editorDataRef,
    handleEditorVisibility,
    updateContent,
    postComment,
    isRequestProcessing,
    labelDropdownEntity,
    handleLabelDropdown,
    editorRef,
    handleTemplate,
  };
};
export default usePartnerSpaceEditor;
