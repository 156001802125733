import React from 'react';

import GuestNavBar from './GuestNavBar';

export default function NavBar(props) {
  switch (props.source) {
    case 'guest':
      return <GuestNavBar {...props} />;
    default:
      return null;
  }
}
