import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from 'app/components/Svg';
import NoEntityFound from 'app/components/NoEntityFound';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { translate } from 'app/actions/flashMessage';

const DEFAULT_MESSAGE = 'No results found.';

class NoEntitiesFound extends Component {
  constructor(props) {
    super(props);
    this.selfMessages = {
      board: 'No spaces were found.',
      folder: 'No folders were found.',
      notification: 'No notifications so far',
      member: 'No members were found.',
    };
    this.iconMapper = {};
  }

  getKey() {
    switch (this.props.type) {
      case 'boards':
        return 'board';
      case 'folder':
        return 'folder';
      default:
        return this.props.type;
    }
  }

  isPatientSearchModal() {
    return this.props.selectionMode && this.props.type === 'contacts';
  }

  getMessage(key) {
    let message;
    if (this.props.searchText) {
      if (this.isPatientSearchModal()) {
        message = translate(
          'RESULTS_NOT_FOUND_ADD_NEW',
          { searchInput: this.props.searchText },
          true,
          { connector: '' },
        );
      } else {
        message = translate('RESULTS_NOT_FOUND', {
          searchInput: this.props.searchText,
        });
      }
    } else {
      message = this.selfMessages[key] || DEFAULT_MESSAGE;
    }
    if (
      this.props.currentUser.graph &&
      this.props.currentUser.graph.is_general_user
    ) {
      return message.replace(/doctors/gi, 'members');
    } else {
      return message;
    }
  }

  getSVGKey(key) {
    switch (key) {
      case 'board':
        return 'noSpaces';
      case 'folder':
        return 'cannotOpen';
      case 'user':
        return 'noUsers';
      case 'member':
        return 'noUsers';
      default:
        return 'noResults';
    }
  }

  getFilterKey() {
    return this.getKey();
  }

  isAtCaseWithMobile() {
    return this.getFilterKey() === 'case' && this.props.device.mobileDevice;
  }

  actionNotRequired() {
    return this.props.noActionRequired;
  }

  isCurrentUserPage() {
    return (
      this.props.user && this.props.user.id === this.props.currentUser.graph.id
    );
  }

  render() {
    let key = this.getFilterKey(),
      iconName = '';
    return (
      <div className={`no-cases-block ${this.props.type}`}>
        <div className='no-cases-image-block'>
          <OrthoIcon
            name={this.iconMapper[key]}
            defaultClass='icon-no-content'
          />
        </div>
        <div className='no-svg-img'>
          <Icon name={iconName || this.getSVGKey(key)} />
        </div>
        <div className='no-cases-message-block'>{this.getMessage(key)}</div>
        <div className='no-button'>
          {' '}
          {!this.actionNotRequired() && this.isCurrentUserPage() && (
            <NoEntityFound entity={key} obj={this.props.user} />
          )}{' '}
        </div>
      </div>
    );
  }
}

NoEntitiesFound = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  {},
)(NoEntitiesFound);

export default NoEntitiesFound;
