import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsGrid from 'app/components/OsGrid';
import RecordsView from 'app/components/RecordsView';

class ClinicTeamWithUsers extends Component {
  getQueryProps() {
    return { type: 'ClinicTeam', queryType: 'CLINIC_DETAILS' };
  }

  render() {
    const sourceObject = this.props.widgetSourceObject;
    if (sourceObject) {
      return (
        <OsGrid identifier='DetailRightSidebar:CareTeamBlock'>
          <RecordsView
            {...this.getQueryProps()}
            idQuery={sourceObject.nice_id}
            page={0}
            perPage={10}
            infiniteScroll={false}
            showMoreNotRequired={true}
            nextPageLoadRequired={false}
            size='small'
            cardGridIdentifier={'CareSpaceColumn:RightSidebarWidget'}
          />
        </OsGrid>
      );
    } else {
      return null;
    }
  }
}

ClinicTeamWithUsers = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(ClinicTeamWithUsers);

export default ClinicTeamWithUsers;
