import { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';

import store from 'app/store';
import { closeTaskPreviewModal } from 'app/actions/taskPreviewModal';
import {
  checkTaskIfOverdue,
  checkTaskIfUnscheduled,
} from 'app/utils/taskHelper';
import { TaskContext } from 'app/context/TaskContext';
import StoreUpdater from 'app/services/StoreUpdater';
import { openInfoModal } from 'app/actions/infoModal';
import { ceil, now, map, lowerCase } from 'app/utils/osLodash';
import {
  removeHashMarkup,
  removeMentioneeName,
} from 'app/utils/textParseHelper';
import { changeMentioneeNameWithId } from 'app/utils/TiptapEditor/editorHelper';
import { commentShareOpen } from 'app/actions/commentShare';
import { flashError } from 'app/actions/flashMessage';

const ACTIVE_TASKS = queryLoader('app/graphql/queries/Task/TaskByWeek.gql');
const DELETE_TASK = queryLoader('app/graphql/mutations/Task/TaskDelete.gql');
const TASK_BY_ID = queryLoader('app/graphql/queries/Task/TaskById.gql');
const TASK_COMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskProcessed.gql',
);
const TASK_UNCOMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskUnprocessed.gql',
);
const UPDATE_DUEDATE = queryLoader(
  'app/graphql/mutations/Task/TaskDueDateChange.gql',
);
const UPDATE_ASSIGNEE = queryLoader(
  'app/graphql/mutations/Task/TaskAssigneeChange.gql',
);
const ADD_TASK_COMMENT = queryLoader('app/graphql/WriteComment.gql');

const useTaskPreview = (initialData) => {
  const dispatch = useDispatch();
  const taskPreviewModal = useSelector((state) => state.taskPreviewModal);
  const currentUser = useSelector((state) => state.currentUser);
  const [markTaskProcessed] = useMutation(TASK_COMPLETED);
  const [markTaskUnprocessed] = useMutation(TASK_UNCOMPLETED);
  const [updateAssignee] = useMutation(UPDATE_ASSIGNEE);
  const [updateDueDate] = useMutation(UPDATE_DUEDATE);
  const [deleteCurrentTask] = useMutation(DELETE_TASK);
  // Task Activity
  const [isCommentSubmitting, setIsCommentSubmitting] = useState(false);
  const [isCommentSubmitDisabled, setIsCommentSubmitDisabled] = useState(false);
  const [commentState, setCommentState] = useState({
    content: '',
    files: [],
    assigningObject: false,
    objects: [],
    mentioneesNotPermitted: [],
    mentionees: [],
  });

  const [addTaskComment] = useMutation(ADD_TASK_COMMENT);

  const {
    updateComponent,
    updateCache,
    changeLabel,
    activeTaskVariables,
    allUserOptions,
  } = useContext(TaskContext);
  const refreshComponent = updateComponent;
  const closeModal = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    dispatch(closeTaskPreviewModal());
  };

  const { data: taskDetails, loading: isTaskDetailsLoading } = useQuery(
    TASK_BY_ID,
    {
      skip: !(taskPreviewModal?.taskId || initialData?.taskId),
      variables: { id: taskPreviewModal?.taskId || initialData?.taskId },
      // fetchPolicy: 'cache-and-network',
    },
  );

  const sanitizeCardId = () => {
    const taskId =
      taskPreviewModal?.taskId || initialData?.taskId || initialData?.task?.id;
    return taskId.split('_')[0];
  };

  const changeDueDate = (dueDate) => {
    const task = taskDetails?.task || initialData?.task;
    updateDueDate({
      variables: {
        id: sanitizeCardId(),
        dueDate: dueDate,
      },
    }).then(({ data }) => {
      if (data?.changeDueDate?.success) {
        // Only for kanban view.
        StoreUpdater.moveTaskToAnotherDay(
          {
            ...task,
            ...data.changeDueDate.entity,
          },
          activeTaskVariables,
          {
            query: ACTIVE_TASKS,
            fromDate: task.due_date,
          },
        );
        if (checkTaskIfOverdue(task)) {
          updateCache(task, 'delete', 'AllTasks');
        }
        if (checkTaskIfUnscheduled(task)) {
          updateCache(task, 'unscheduled_delete', 'AllTasks');
        }
      }
    });
  };

  const markAsDone = () => {
    const task = taskDetails?.task || initialData?.task;
    const cardId = sanitizeCardId();
    markTaskProcessed({
      variables: { id: cardId },
    }).then(({ data }) => {
      if (checkTaskIfOverdue(task)) {
        updateCache(
          { ...task, ...data.markTaskProcessed.entity },
          'delete',
          'OverDueTasks',
        );
      }
    });
  };

  const markAsNotDone = () => {
    const cardId = sanitizeCardId();
    markTaskUnprocessed({
      variables: { id: cardId },
    }).then(({ data }) => {
      // updateOverdueOnAction({ ...card, id: cardId }, true);
      refreshComponent();
    });
  };

  const getCardType = () => {
    const task = taskDetails?.task || initialData?.task;
    switch (task.recursive_type) {
      case 'daily':
        return 'DAILY';
      case 'weekly':
        return 'WEEKLY';
      case 'monthly':
        return 'MONTHLY';
      case 'weekdays_only':
        return 'WEEKDAYS';
      default:
        return '';
    }
  };

  const changeAssignee = (selectedValue) => {
    const task = taskDetails?.task || initialData?.task;
    if (task.assignee.id !== (selectedValue?.id || selectedValue?.entity?.id)) {
      updateAssignee({
        variables: {
          id: task.id,
          assigneeId: selectedValue?.id || selectedValue?.entity?.id,
          assigneeType: 'User',
        },
      }).then(({ data }) => {
        if (data.changeAssignee.success) {
          updateCache(task, 'delete', 'AllTasks');
        }
      });
    }
  };

  const deleteTask = ({ hideModal }) => {
    deleteCurrentTask({
      variables: {
        id: sanitizeCardId(),
      },
    }).then(({ data }) => {
      hideModal && hideModal();
      if (!data.deleteTask.success) {
        store.dispatch(openInfoModal('general', 'error_message'));
      } else {
        if (data.deleteTask.entity.recursive_type !== 'non_recursive') {
          refreshComponent();
        } else {
          updateCache(data.deleteTask.entity, 'delete', 'AllTasks');
        }
      }
    });
  };

  const handleTaskContent = (content) => {
    setCommentState((prev) => ({ ...prev, content }));
  };

  const getCommentOptimisticResponse = (data) => {
    let { objects, type, content } = data;

    const task = taskDetails?.task || initialData?.task;
    return {
      additional_replies: [],
      author: currentUser.graph,
      commentable_id: task?.id,
      commentable_name: task?.title || '',
      commentable_path: 'commentable_path', // Dev ToDo
      commentable_type: 'Task',
      content,
      created_at: ceil(now() / 1000),
      emoji_reaction_detail: {
        __typename: 'EmojiReactionDetail',
        id: now(),
        emoji_reaction_users: [],
      },
      files: getOptimisticResponseForFiles(),
      frontend_url: '',
      hash_tags: [],
      id: '-1',
      images_and_videos: [],
      links: objects,
      mention_groups: task?.mention_groups,
      // mentionees: state.mentionees,
      nice_id: '',
      // parent_id: props.parentId || null,
      pinnable: false,
      pinned: false,
      pinned_at: null,
      processed: true,
      replies_count: 0,
      type,
      updated_at: ceil(now() / 1000),
      view_count: 0,
      viewed_by: [],
      visible_audience_ids: [],
      __typename: 'Comment',
    };
  };

  const getOptimisticResponseForFiles = () => {
    return commentState.files.map((file, index) => {
      return {
        __typename: 'MessageFile',
        id: 'MessageFile:' + file.id,
        file_asset: file,
        position: index + 1,
      };
    });
  };

  const getActivityOptimisticResponse = (data) => {
    let template_text = 'You posted in %{[link:nice_id]entity.feed_name}';
    return {
      writeComment: {
        __typename: 'Response',
        success: true,
        errors: null,
        entity: {
          __typename: 'Activity',
          id: '-1',
          source_type: 'TaskCommentActivity',
          template_text,
          visible_at: ceil(now() / 1000),
          authors: [currentUser.graph],
          effect_activity: null,
          entities: [{ __typename: 'Task' }],
          objects: [getCommentOptimisticResponse(data)],
        },
        isOptimisticResponse: true,
      },
    };
  };

  const assignCommentObject = (type) => {
    openCommentShare(type);
  };

  function openCommentShare(type, files = []) {
    setCommentState((prev) => ({ ...prev, assigningObject: true }));
    dispatch(
      commentShareOpen(
        type,
        'Comment',
        files,
        commentState.objects.concat(commentState.files),
        {
          restrictModalOpening: type === 'video',
        },
      ),
    );
  }
  const handleExternalEntityShare = (files) => {
    openCommentShare('file', files);
  };

  const onCommentFileDrop = (files) => {
    files.length && handleExternalEntityShare(files);
  };

  const resetValues = () => {
    setCommentState((prev) => ({
      ...prev,
      content: '',
      files: [],
      objects: [],
      mentioneesNotPermitted: [],
      mentionees: [],
      requestInProgress: false,
    }));
  };

  const isCommentValid = (type) => {
    let isValid = true;
    if (
      !(
        commentState.content?.trim().length ||
        commentState.files?.length ||
        commentState.objects?.length
      )
    ) {
      setCommentState((prev) => ({ ...prev, hasError: lowerCase(type) }));
      isValid = false;
      dispatch(flashError('No text added', false));
    }

    return isValid;
  };

  const handleCommentSubmit = async (val) => {
    const { limit, updateActivities, queryVariables } = val;
    setIsCommentSubmitting(true);
    setIsCommentSubmitDisabled(true);
    const task = taskDetails?.task || initialData?.task;

    const type = 'Discussion';
    if (isCommentValid(type)) {
      let data = {
        content: removeHashMarkup(
          removeMentioneeName(
            changeMentioneeNameWithId(commentState.content.toString()),
          ),
        ),
        objects: commentState.objects,
        type,
      };

      await addTaskComment({
        variables: {
          commentableId: task?.original_task_or_self_id,
          commentableType: 'Task',
          relevantEntityId: task?.id,
          relevantEntityType: 'Task',
          content: data.content,
          links: data.objects.map((link) => `${link.__typename}:${link.id}`),
          files: map(commentState.files, (file) => file.id),
          type: data.type,
        },
        ...getActivityOptimisticResponse(data),
        update: (proxy, { data: { writeComment } }) => {
          let activity = writeComment.entity;
          let recordIdToRemove = activity.id === '-1' ? null : '-1';

          if (
            task.recent_mentioned_comments &&
            activity.objects[0] &&
            activity.objects[0].__typename === 'Comment'
          ) {
            StoreUpdater.addCommentsInTaskInboxListing(activity.objects[0], {
              queryVariables,
              task,
            });
          } else {
            StoreUpdater.addCommentInTaskActivityListing(activity, {
              recordIdToRemove,
              idQuery: task?.original_task_or_self_id,
              limit: limit,
              updateActivities: updateActivities,
              taskActivityId: task?.recent_activity_id || null,
            });
          }
        },
      });
      setIsCommentSubmitting(false);
      setIsCommentSubmitDisabled(false);
      // Reset values
      resetValues();
    }
  };

  return {
    allUserOptions,
    assignCommentObject,
    changeAssignee,
    changeDueDate,
    changeLabel,
    commentState,
    deleteTask,
    getCardType,
    handleCommentSubmit,
    handleTaskContent,
    isCommentSubmitting,
    isCommentSubmitDisabled,
    isTaskDetailsLoading,
    markAsDone,
    markAsNotDone,
    onCommentFileDrop,
    refreshComponent,
    setCommentState,
    taskDetails,
    taskPreviewModal,
    updateCache,
    closeModal,
  };
};

export default useTaskPreview;
