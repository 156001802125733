import { loader as queryLoader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import {
  commentShareOpen,
  commentShareConsume,
} from 'app/actions/commentShare';
import { openPoliciesModal } from 'app/actions/policiesModal';
import { updateCommentDraft, clearDraft } from 'app/actions/drafts';
import { getDraftKey } from 'app/reducers/drafts';
import usePreviousValue from 'app/hooks/usePreviousValue';
import { addAttachmentBannerKey } from 'app/utils/spaceHelper';
import AttachmentDropdown from 'app/components/Shared/AttachmentDropdown';
import OsCards from 'app/components/OsCards';
import {
  ceil,
  isEmpty,
  isEqual,
  lowerCase,
  map,
  now,
  reject,
  uniqBy,
} from 'app/utils/osLodash';
import { buildFormattedIds } from 'app/utils/entitiesHelper';
import VideoRecordButton from 'app/components/Shared/VideoRecordButton';
import { flashError, translate } from 'app/actions/flashMessage';
import { changeMentioneeNameWithId } from 'app/utils/TiptapEditor/editorHelper';
import {
  parseContentWithTag,
  removeHashMarkup,
  removeMentioneeName,
} from 'app/utils/textParseHelper';
import EventTracker from 'app/services/EventTracker';
import { COMMENT_ATTACHMENT_BATCH_SIZE } from 'app/constants';
import StoreUpdater from 'app/services/StoreUpdater';
import {
  COMMENTS_BATCH_SIZE,
  REPLIES_BATCH_SIZE,
} from 'app/components/CommentSection/constants';
import { useDispatch } from 'react-redux';
import OsGrid from 'app/components/OsGrid';
import NewMessageEditor from 'app/components/TeamSpaceCommunicationsView/NewMessageEditor';
import { keyboardUIAdditionalBarSupported } from 'app/utils/deviceHelper';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import ParseText from 'app/components/ParseText';
const EDIT_COMMENT_MUTATION = queryLoader('app/graphql/EditComment.gql');
const WRITE_COMMENT_MUTATION = queryLoader('app/graphql/WriteComment.gql');

const NewSpaceMessageWrapper = (props) => {
  const initialContentRef = useRef('');
  const inputRef = useRef('');
  const draftKey = useRef(null);
  const editorRef = useRef(null);
  const replyObjectRef = useRef({
    parentId: props.parentId,
    isReplying: props.isReplying,
  });
  const disallowDraftSupport = () => {
    return props.editComment;
  };

  const getDraft = () => {
    if (disallowDraftSupport()) return {};

    const draftLookUpFields = {
      userId: props.currentUser.graph && props.currentUser.graph.id,
      commentableType: props.commentableType,
      commentableId: props.commentableId,
      parentId: props.parentId,
    };

    draftKey.current =
      draftKey.current || getDraftKey.comment(draftLookUpFields);
    return props.drafts[draftKey.current] || {};
  };
  const initializeFromDraft = () => {
    const {
      content: draftContent = '',
      files = [],
      objects = [],
      mentionees = [],
      commentCategoryIds = [],
      commentType: type = '',
    } = getDraft();
    const state = props.location.state;

    const content = !isEmpty(state)
      ? translate(state.prefilledTextKey, state.prefilledTextProps)
      : draftContent;

    return {
      files,
      objects,
      content,
      mentionees,
      commentCategoryIds,
      type,
    };
  };

  const [state, setState] = useState({
    assigningObject: false,
    mentioneesNotPermitted: [],
    mentionees: [],
    ...initializeFromDraft(),
  });

  const [processingRequest, setProcessingRequest] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    window.addEventListener('orientationchange', scrollTextAreaIntoView);

    return () => {
      window.removeEventListener('orientationchange', scrollTextAreaIntoView);
      saveDraft();
    };
  }, []);

  useEffect(() => {
    saveDraft();
  }, [state]);

  const scrollTextAreaIntoView = () => {
    if (document.activeElement === inputRef.current) {
      window.setTimeout(() => {
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight,
        );
      }, 500);
    }
  };

  const removeObject = (object) => {
    let objects = state.objects,
      newObjects = reject(objects, {
        __typename: object.__typename,
        id: object.id,
      });

    setState((p) => ({ ...p, objects: newObjects }));
  };

  const removeAttachment = (file) => {
    let { files } = state,
      newFiles = reject(files, { id: file.id });

    setState((p) => ({ ...p, files: newFiles }));
  };

  const changeContent = (content) => {
    setContent(content);
  };

  const onDrop = (files) => {
    files.length && assigningObject('file', files);
  };

  const assigningObject = (type, files = []) => {
    assignObject(type, files);
  };

  const assignObject = (type, files = [], options = {}) => {
    setState((prev) => ({ ...prev, assigningObject: true }));
    props.commentShareOpen(
      type,
      'Comment',
      files,
      state.objects.concat(state.files),
      {
        commentableType: props.commentableType,
        bannerKey: addAttachmentBannerKey(props.entity),
        restrictModalOpening: type === 'video',
        ...options,
      },
    );
  };

  const shouldAutoFocus = () => {
    return !props.disabled && !props.device.touchSupport;
  };

  const isBtnDisabled = () => {
    const { objects, files } = state;
    return (
      processingRequest ||
      !(
        (content && content.trim() && content !== '<p></p>') ||
        files.length > 0 ||
        objects.length > 0
      )
    );
  };

  const onFieldFocus = (editor, event) => {
    if (keyboardUIAdditionalBarSupported()) {
      window.$('html')[0].classList.add('keyboard-ui-additional-bar-visible');
      if (props.isFullPageListing && props.device.ipad) {
        let messagesContainer =
          document.getElementsByClassName('list-messages')[0];
        messagesContainer &&
          setTimeout(() => {
            messagesContainer.scrollBy(0, 40);
          }, 200);
      }
    }

    if (replyObjectRef.current.isReplying) {
      const mention = getAllMentionees();
      if (mention) editor.commands.setContent(mention);
    }
    !props.isFullPageListing &&
      window.$('html')[0].classList.add('floater-focus');
  };

  const addEntity = (entity) => {
    if (entity) {
      let { objects } = state;
      objects.push(entity);
      setState({ objects });
    }
  };

  const addTemplate = (template, editor) => {
    let lastPosition = editor.state.doc.nodeSize - 3;
    editor?.commands.insertContentAt(lastPosition, template.content);
    if (template.files) {
      setState((prev) => ({ ...prev, files: template.files }));
    }
    if (template.links) {
      setState((prev) => ({ ...prev, objects: template.links }));
    }
    editor?.chain().focus('end').run();
  };

  const dispatch = useDispatch();
  const previousProps = usePreviousValue(props);
  const { onFilesUpdate = () => {}, onObjectsUpdate = () => {} } = props;

  useEffect(() => {
    if (props.editComment) {
      let updatedContent = parseContentWithTag(props);
      setContent(updatedContent || '');
    }

    if (props.prefilledContent) {
      setContent(props.prefilledContent);
    }

    if (props.preSelectedObjects) {
      setState((prev) => ({ ...prev, objects: props.preSelectedObjects }));
    }

    if (props.preSelectedFiles) {
      setState((prev) => ({ ...prev, files: props.preSelectedFiles }));
    }
    saveDraft();
  }, []);

  useEffect(() => {
    if (
      state.assigningObject &&
      previousProps?.commentShare.status === 'open' &&
      props.commentShare.status === 'closed'
    ) {
      setState((p) => ({ ...p, assigningObject: false }));
    }

    if (
      state.assigningObject &&
      !isEqual(props.commentShare.cards, props.commentShare.cards)
    ) {
      let files = state.files,
        objects = state.objects;
      files = files.concat(props.commentShare.cards.files);
      objects = objects.concat(props.commentShare.cards.objects);

      setState((prev) => ({ ...prev, files, objects, assigningObject: false }));
      previousProps?.commentShareConsume();
    }

    if (previousProps?.entity.id.toString() !== props.entity.id.toString()) {
      saveDraft();
      draftKey.current = '';
      setState((prev) => ({ ...prev, files: [], objects: [] }));
      setContent('');
    }

    if (+previousProps?.entity.id !== +props.entity.id) {
      shouldAutoFocus() && inputRef.current.focus();

      const { objects: existingObjects = [], links: existingLinks = [] } =
        state;

      if (content || existingObjects.length || existingLinks.length) return;

      const { content: draftContent, links = [], objects = [] } = getDraft();

      setState((prev) => ({ ...prev, links, objects }));
      setContent(draftContent || '');
    }
  }, [props]);

  useEffect(() => {
    if (
      state.assigningObject &&
      !isEqual(previousProps?.commentShare.cards, props.commentShare.cards)
    ) {
      setTimeout(() => {
        let files = state.files,
          objects = state.objects;
        files = files.concat(props.commentShare.cards.files);
        objects = objects.concat(props.commentShare.cards.objects);
        setState((p) => ({
          ...p,
          files: files,
          objects: objects,
          assigningObject: false,
        }));
        onFilesUpdate(files);
        onObjectsUpdate(objects);
        props.commentShareConsume();
      }, 1000);
    }
  }, [props.commentShare]);

  const addMentionee = (author) => {
    let mentionees = state.mentionees;
    let alreadyAdded = mentionees.find(
      (suggestion) => suggestion.id.toString() === author.id.toString(),
    );
    if (!alreadyAdded) {
      mentionees.push(author);
      setState((prev) => ({ ...prev, mentionees }));
    }
  };

  const authorAndCurrentUserAreNotSame = () => {
    return (
      !!props.author &&
      props.author.id.toString() !== props.currentUser.graph.id.toString()
    );
  };

  const mentioneesRef = useRef([]);

  const addAllMentionees = (mentionees) => {
    let mentioneeString = '';
    const uniqMentionees = uniqBy(mentionees, 'id');
    uniqMentionees.forEach((mentionee) => {
      mentioneeString += `<span data-type="mention" data-id="${mentionee.id}" data-label="${mentionee.name}" data-typename="User"'>${mentionee.name}</span> `;
    });
    return mentioneeString;
  };

  const getAllMentionees = () => {
    if (replyObjectRef.current.parentId && props.newComment) {
      let allMentionee = addAllMentionees(mentioneesRef.current || []);
      if (authorAndCurrentUserAreNotSame()) {
        addMentionee(props.author);
        allMentionee += `<span data-type="mention" data-id="${props.author.id}" data-label="${props.author.name}" data-typename="User"'>${props.author.name}</span> `;
      }
      return allMentionee;
    }
  };

  const trackCommentFailed = (reason) => {
    EventTracker.trackFailure('add_comment', { reason });
  };

  const isCommentValid = (type) => {
    let isValid = true;
    if (
      !(content?.trim().length || state.files?.length || state.objects?.length)
    ) {
      setState((prev) => ({ ...prev, hasError: lowerCase(type) }));
      trackCommentFailed('No text added');
      isValid = false;
      flashError('No text added', false)(dispatch);
    }

    return isValid;
  };

  const saveDraft = () => {
    if (disallowDraftSupport()) return;

    const { files, objects, mentionees, commentCategoryIds, type } = state;

    const { commentableType, commentableId, parentId } = props;

    const payload = {
      content,
      files,
      objects,
      commentableType,
      commentableId,
      parentId,
      mentionees,
      commentCategoryIds,
      type,
    };
    props.updateCommentDraft(payload);
  };

  const clearDraft = () => {
    if (draftKey.current) {
      props.clearDraft(draftKey.current);
    }
  };

  const resetValues = () => {
    setState((prev) => ({
      ...prev,
      newCommentOpen: false,
      files: [],
      objects: [],
      mentioneesNotPermitted: [],
      mentionees: [],
    }));
    setProcessingRequest(false);
    setContent('');
    initialContentRef.current = '';
  };

  const editComment = (data) => {
    let { content, objects, type } = data;
    // setState((prev) => ({
    //   ...prev,
    //   submitButtonToggled: !prev.submitButtonToggled,
    // }));
    props.closeEdit();
    props.client.mutate({
      mutation: EDIT_COMMENT_MUTATION,
      variables: {
        commentId: props.id,
        content,
        files: state.files.map((file) => file.id),
        links: buildFormattedIds(objects),
        type,
      },
      optimisticResponse: ({ content }) => {
        return {
          editComment: {
            ...getCommentOptimisticResponse(data),
            categories: props.categories,
            content,
            created_at: props.created_at,
            files: getOptimisticResponseForFiles(),
            hash_tags: props.hash_tags,
            id: props.id,
            liked: props.liked,
            likes_by_users: props.likes_by_users,
            likes_count: props.likes_count,
            links: objects,
            pinnable: props.pinnable,
            pinned: props.pinned,
            pinned_at: props.pinned_at,
            replies_count: props.replies_count || 0,
            total_likes: props.total_likes,
            type,
            updated_at: props.updated_at,
            emoji_reaction_detail: props.emoji_reaction_detail,
          },
        };
      },
    });
  };

  const storeUpdateRequired = () => {
    return !props.refetchOnCreate && (props.newComment || props.threadOpen);
  };

  const getOptimisticResponseForFiles = () => {
    return state.files.map((file, index) => {
      return {
        __typename: 'MessageFile',
        id: 'MessageFile:' + file.id,
        file_asset: file,
        position: index + 1,
      };
    });
  };

  const getCommentOptimisticResponse = (data) => {
    let { objects, type, content } = data;

    return {
      additional_replies: [],
      author: props.currentUser.graph,
      commentable_id: props.commentableId,
      commentable_name: props.commentable_name || '',
      commentable_path: 'commentable_path', // Dev ToDo
      commentable_type: props.commentableType,
      content,
      created_at: ceil(now() / 1000),
      emoji_reaction_detail: {
        __typename: 'EmojiReactionDetail',
        id: now(),
        emoji_reaction_users: [],
      },
      files: getOptimisticResponseForFiles(),
      frontend_url: '',
      hash_tags: [],
      id: '-1',
      images_and_videos: [],
      links: objects,
      mention_groups: props.entity.mention_groups,
      mentionees: state.mentionees,
      nice_id: '',
      parent_id: props.parentId || null,
      pinnable: false,
      pinned: false,
      pinned_at: null,
      processed: true,
      replies_count: 0,
      type,
      updated_at: ceil(now() / 1000),
      view_count: 0,
      viewed_by: [],
      visible_audience_ids: [],
      __typename: 'Comment',
    };
  };

  const getActivityOptimisticResponse = (data) => {
    let template_text = 'You posted in %{[link:nice_id]entity.feed_name}';
    return {
      writeComment: {
        __typename: 'Response',
        success: true,
        errors: null,
        entity: {
          __typename: 'Activity',
          id: '-1',
          source_type: 'SpaceCommentActivity',
          template_text,
          visible_at: ceil(now() / 1000),
          authors: [props.currentUser.graph],
          effect_activity: null,
          entities: [{ __typename: 'Board' }],
          objects: [getCommentOptimisticResponse(data)],
        },
        isOptimisticResponse: true,
      },
    };
  };

  const closeModal = () => {
    if (state.isModalOpen)
      setState((prev) => ({ ...prev, isModalOpen: false }));
  };

  const newComment = (data) => {
    let { content, objects, type } = data;
    setProcessingRequest(true);
    props.client
      .mutate({
        mutation: WRITE_COMMENT_MUTATION,
        variables: {
          commentableId: props.commentableId,
          commentableType: props.commentableType,
          parentId: props.parentId,
          content,
          type: type,
          links: buildFormattedIds(objects),
          files: map(state.files, (file) => file.id),
        },
        ...getActivityOptimisticResponse(data),
        update: (proxy, { data: { writeComment } }) => {
          let activity = writeComment.entity;
          let isCommentReply = isReply();
          let recordIdToRemove = activity.id === '-1' ? null : '-1';
          if (storeUpdateRequired()) {
            StoreUpdater.addCommentInGroupActivityListing(activity, {
              recordIdToRemove,
              recordId: props.commentableId,
              recordType: props.commentableType,
              sortQuery: 'recent',
              textQuery: props.textQuery,
              queryType: 'COMMENTS_ACTIVITY_LISTING',
              limit: COMMENTS_BATCH_SIZE,
            });

            let commentData = activity.objects[0];
            let idToBeRemoved = commentData.id === '-1' ? null : '-1';
            if (isCommentReply) {
              // Include message under reply
              StoreUpdater.addRecordInRecordPager(
                {
                  public_feed: null,
                  additional_replies: [],
                  ...commentData,
                },
                {
                  afterId: null,
                  aroundId: props.aroundId,
                  beforeId: null,
                  idToBeRemoved,
                  limit: REPLIES_BATCH_SIZE,
                  recordId: isCommentReply
                    ? props.parentId
                    : props.commentableId,
                  recordType: 'Comment',
                  sortQuery: 'recent',
                  textQuery: props.textQuery,
                  queryType: 'SPACE_MESSAGES',
                  type: 'Replies',
                },
              );
            }

            if (commentData?.files.length > 0) {
              commentData.files.forEach((file) => {
                const fileData = {
                  ...file?.file_asset,
                };
                StoreUpdater.addRecordInRecordPager(fileData, {
                  afterId: null,
                  aroundId: props.aroundId,
                  beforeId: null,
                  idToBeRemoved,
                  limit: COMMENT_ATTACHMENT_BATCH_SIZE,
                  recordId: props.commentableId,
                  recordType: 'Board',
                  queryType: 'COMMENTS',
                  type: 'AttachmentsInCareDiscussions',
                });
              });
            }
          }
        },
      })
      .then((data) => {
        let comment = data.data.writeComment;
        !isEmpty(props.entity) && sendAddCommentEvent(comment);

        clearDraft();
        props.onClose && props.onClose();
        closeModal();
        resetValues();
        props.refetchOnCreate && props.refetchOnCreate(comment);
        if (isReply()) {
          props.setScrollToCommentId(comment.id);
        }
      });
  };

  const commentType = (type) => {
    return Boolean(props.parentId)
      ? 'Reply'
      : type === 'Discussion'
      ? 'Comment'
      : 'Question';
  };

  const sendAddCommentEvent = (data) => {
    let payload = {
      ...props.entity,
      type: commentType(data.type),
      threaded: Boolean(props.parentId) ? 'Yes' : 'No',
    };

    EventTracker.trackForEntity('add_comment', payload);
  };

  const isReply = () => {
    return !!props.parentId;
  };

  const postComment = (type) => {
    let data = {
      content: removeHashMarkup(
        removeMentioneeName(changeMentioneeNameWithId(content.toString())),
      ),
      objects: state.objects,
      type,
    };
    if (props.content) {
      editComment(data);
    } else {
      newComment(data);
    }
  };

  const createComment = (type) => {
    type = type || 'Discussion';
    setState((p) => ({ ...p, type }));
    if (isCommentValid(type)) {
      if (props.commentableId) {
        postComment(type);
      }
    }
  };

  const handleSubmit = () => {
    createComment();
  };

  const mentionGroups = () => {
    return props.entity?.mention_groups || [];
  };

  const showReplyParent = () => {
    const { replyObject } = props;
    let parentClass = 'parent-msg-box ';
    return (
      <div className='conversation-message-box'>
        <div className='c-close'>
          <OrthoIcon name='close' onClick={props.onClose} />
        </div>
        <div className={parentClass}>
          <div className='c-author'>{replyObject.message.author.name}</div>
          <div className='c-text'>
            <ParseText
              obj={replyObject.message}
              className='conversation-message-text'
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (props.isReplying) {
      const filterOwnMentions = props.mentionees?.filter(
        (mentionee) =>
          mentionee.id.toString() !== props.currentUser.graph.id.toString(),
      );
      const uniqMentionees = uniqBy(filterOwnMentions, 'id');
      mentioneesRef.current = uniqMentionees;

      replyObjectRef.current = {
        parentId: props.parentId,
        isReplying: props.isReplying,
      };
      editorRef.current.focusEditor();
    }
  }, [props.isReplying]);

  return (
    <div id={'new-message-editor'} ref={inputRef}>
      {props.isReplying && showReplyParent()}
      <div id={'editor-box'} className={'editor-attachments'}>
        {state.files.map((object) => {
          if (object) {
            return (
              <OsGrid
                identifier={`DiscussionCard:Cardcols`}
                key={`${object.__typename}:${object.id}`}>
                <OsCards
                  size={'extra-small'}
                  disableCard={false}
                  obj={object}
                  selectionMode={false}
                  closeRequired={true}
                  afterClose={removeAttachment}
                  avoidPreviewModal={false}
                />
              </OsGrid>
            );
          }
        })}
        {state.objects.map((object) => {
          if (object) {
            return (
              <OsGrid
                identifier={`DiscussionCard:Cardcols`}
                key={`${object.__typename}:${object.id}`}>
                <OsCards
                  size={'extra-small'}
                  disableCard={false}
                  obj={object}
                  source='Comment'
                  selectionMode={false}
                  closeRequired={true}
                  afterClose={removeObject}
                  avoidPreviewModal={false}
                  isTaskModal={true}
                />
              </OsGrid>
            );
          }
        })}
      </div>
      <div className={'options'} style={{}}>
        <div style={{ display: 'flex' }}>
          <AttachmentDropdown
            leftAligned={true}
            textRequired={false}
            assignObject={assigningObject}
          />
          <VideoRecordButton
            onClick={(event) => {
              event.stopPropagation();
              assigningObject('video');
            }}
            extraClass={`no-text with-border web-view-btn`}
          />
          <VideoRecordButton
            onClick={(event) => {
              event.stopPropagation();
              assignObject('video', [], {
                isOnlyAudioRecording: true,
              });
              assigningObject('video');
            }}
            icon={'mic'}
            extraClass={`no-text with-border web-view-btn`}
          />
        </div>
      </div>
      <NewMessageEditor
        editorRef={editorRef}
        placeholder={'Write a message...'}
        updateContent={changeContent}
        isSubmitting={processingRequest}
        handleSubmit={handleSubmit}
        initialContent={initialContentRef.current}
        files={state.files}
        onFieldFocus={onFieldFocus}
        isSubmitButtonDisabled={isBtnDisabled()}
        objects={state.objects}
        getMentionGroups={mentionGroups}
        isFocused={false}
        addPatientEntity={addEntity}
        entity={props.entity}
        handleTemplate={addTemplate}
        onDrop={onDrop}
        editorType={'groups'}
      />
    </div>
  );
};

const NewSpaceMessageApollo = withApollo(NewSpaceMessageWrapper);
const NewSpaceMessageConnect = connect(
  ({ currentUser, commentShare, device, drafts }) => {
    return {
      currentUser,
      commentShare,
      device,
      drafts,
    };
  },
  {
    commentShareOpen,
    commentShareConsume,
    openPoliciesModal,
    updateCommentDraft,
    clearDraft,
  },
)(NewSpaceMessageApollo);
const NewSpaceMessage = withRouter(NewSpaceMessageConnect);
export default NewSpaceMessage;
