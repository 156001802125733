import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragOverlay } from '@dnd-kit/core';
import { Modal } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import Slider from 'react-slick';

import EventTracker from 'app/services/EventTracker';

import AddLinkCard from 'app/components/BoardView/AddLinkCard';
import GeneralModal from 'app/components/GeneralModal';
import ListCards from 'app/components/ListCards';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import OsGrid from 'app/components/OsGrid';
import RenderLoadingCards from './RenderLoadingCards';
import VisitingCard from 'app/components/VisitingCard';
import { Draggable } from 'app/components/DnD/Draggable';
import { Droppable } from 'app/components/DnD/Droppable';

import {
  activateBodyScroll,
  deactivateBodyScroll,
  isObjectExist,
} from 'app/utils/generalHelper';
import { animateSupported } from 'app/utils/domHelper';
import { capitalize, pluralizeString } from 'app/utils/stringHelper';
import { cardSize } from 'app/utils/osCardHelper';
import {
  find,
  includes,
  indexOf,
  isEmpty,
  slice,
  times,
} from 'app/utils/osLodash';

import NewFolderCard from 'app/components/OsCards/NewFolderCard';
import { entityIsAdditionalLink } from 'app/utils/entitiesHelper';

import { translate } from 'app/actions/flashMessage';
import { isCareSpace, isFolderSpace } from 'app/utils/spaceHelper';
import { entityIsCase } from 'app/utils/entitiesHelper';
import {
  isCurrentDay,
  isDateInPreviousWeek,
  isDateInThisWeek,
  isYesterday,
  timeWithFormat,
} from 'app/utils/timeHelper';
import { DAY_WINDOW_SEGREGATION, PATIENT_DIRECTORY_TYPES } from 'app/constants';
import PatientSegregationList from 'app/components/VisitorsListing/PatientSegregationList';

const ON_DEMAND_LOADING_DEFAULTS = {
  initialBatchSize: 3,
  batchSize: 9,
};

// Render the card view from provided objects
class RenderCards extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      attachment: {},
      cardsToRender:
        props.initialBatchSize ||
        ON_DEMAND_LOADING_DEFAULTS['initialBatchSize'],
      showModal: false,
      showFeatureNotAvailableModal: false,
      dropdDownOpenCardHash: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filter !== nextProps.filter && nextProps.onDemandLoading) {
      this.setState({
        cardsToRender:
          nextProps.initialBatchSize ||
          ON_DEMAND_LOADING_DEFAULTS['initialBatchSize'],
      });
    }
  }

  loadNextBatch = () => {
    this.setState({
      cardsToRender:
        this.state.cardsToRender +
        (this.props.batchSize || ON_DEMAND_LOADING_DEFAULTS['batchSize']),
    });
  };

  totalNumberOfCards() {
    return this.props.objects.length;
  }

  toggleModal = () => {
    let callBackFunction = this.state.showModal
      ? activateBodyScroll
      : deactivateBodyScroll;
    this.setState({ showModal: !this.state.showModal }, callBackFunction);
  };

  isSmallCardRequired = (object) => {
    // ToDo: Refactor this component's row and column logic
    // return SMALL_CARDS_TYPES.includes(object.__typename) || (object.__typename === "UniversalCard" && object.size === "small")
    return false;
  };

  getColWiseCollection() {
    var collection = [];
    var objects = this.props.objects.slice(0);

    while (objects.length > 0) {
      var currentCard = objects.shift();
      if (currentCard && this.isSmallCardRequired(currentCard)) {
        var nextUser = find(objects, this.isSmallCardRequired);
        if (typeof nextUser !== 'undefined') {
          objects.splice(indexOf(objects, nextUser), 1);
          collection.push([currentCard, nextUser]);
        } else {
          collection.push([currentCard]);
        }
      } else {
        collection.push([currentCard]);
      }
    }
    return collection;
  }

  renderAddLinkCard() {
    return (
      <>
        <AddLinkCard
          options={this.props.newLinkOptions}
          abilityObject={this.props.newLinkOptions.abilityObject}
          cardsCustomMarginRequired={this.props.cardsCustomMarginRequired}
        />
        {this.props.canArrangeContent &&
          this.totalNumberOfCards() === 0 &&
          !this.props.autofillCardsUpto && (
            <Droppable
              id={'section:' + this.props.tabSectionId + ':link:'}
              disabled={this.props.spaceLinks.activeDragType !== 'link'}
              className='add-new-section'>
              <OrthoIcon name='add-new-section' dataHoverNotRequired={true} />
            </Droppable>
          )}
      </>
    );
  }

  getCardGridIdentifer() {
    return (
      this.props.cardProps?.cardGridIdentifier || this.props.cardGridIdentifier
    );
  }

  renderLoadingCards(count, previousCardsCount) {
    return (
      <RenderLoadingCards
        {...this.getLoadingCardsProps()}
        canArrangeContent={this.props.canArrangeContent}
        cardGridIdentifier={this.getCardGridIdentifer()}
        cardsCustomMarginRequired={this.props.cardsCustomMarginRequired}
        count={count}
        previousCardsCount={previousCardsCount}
        siblings={true}
        size={cardSize({
          size: this.props.size,
          width: this.props.device.width,
          mobileDeviceLandscape: this.props.device.mobileDeviceLandscape,
        })}
      />
    );
  }

  renderNewFolder(props) {
    return (
      <OsGrid identifier={this.props.cardGridIdentifier}>
        <NewFolderCard
          toggleCreate={props.toggleCreate}
          space={this.context?.space || props?.space}
        />
      </OsGrid>
    );
  }

  renderDynamicTemplateCard = (id) => {
    return (
      <OsGrid identifier={this.props.cardGridIdentifier} key={id}>
        {this.renderCardOrList({ __typename: 'DynamicTemplate', id })}
      </OsGrid>
    );
  };

  getDynamicTemplateCards() {
    return this.props.dynamicTemplates.map(this.renderDynamicTemplateCard);
  }

  renderCollection = (props) => {
    let collection = [],
      cardsCount =
        this.cardsCollection().length +
        (this.props.addNewLinkAvailable ? 1 : 0);
    collection.push(...this.getDynamicTemplateCards());
    if (props && props.createFolder)
      collection.push(this.renderNewFolder(props));
    if (this.props.addNewLinkAvailable)
      collection.push(this.renderAddLinkCard());
    if (this.props.orderRowWise) {
      collection.push(this.renderRowWiseCollection());
    } else {
      collection.push(this.renderColWiseCollection(props));
    }
    if (
      this.props.autofillCardsUpto &&
      this.props.autofillCardsUpto > this.getRowWiseCollection().length
    ) {
      collection.push(
        this.renderLoadingCards(
          this.props.autofillCardsUpto - this.getRowWiseCollection().length,
          cardsCount,
        ),
      );
    }
    // if(true || this.props.autofillCardsUpto && this.props.autofillCardsUpto > this.getRowWiseCollection().length)
    //   collection.push(this.renderLoadingCards(10, cardsCount))
    return collection;
  };

  showFeatureNotAvailable = () => {
    this.setState({ showFeatureNotAvailableModal: true });
  };

  isCaseInCareSpace(obj) {
    return (
      false &&
      isCareSpace(this.props.space) &&
      ((entityIsAdditionalLink(obj) && entityIsCase(obj.entity)) ||
        entityIsCase(obj))
    );
  }

  renderCardOrList(obj, modalProps, index) {
    let selected = isObjectExist(this.props.selectedRecords, obj);
    let props = {
      obj,
      size: this.props.size,
      onCardClick: modalProps?.onCardClick || this.props.onCardClick,
      closeRequired: this.props.closeRequired,
      afterClose: this.props.afterClose,
      selectionMode: this.props.selectionMode,
      avoidModalActions: this.props.avoidModalActions,
      caseSelectionPossible: this.props.caseSelectionPossible,
      toggleCaseSelection: this.props.toggleCaseSelection,
      caseSelectionActivated: this.props.caseSelectionActivated,
      onEntityShow: this.props.onEntityShow,
      openInNewTab: this.props.openInNewTab,
      multiSelectMode: this.props.multiSelectMode,
      selected: this.props.selected || selected,
      cardClickRequired: this.props.cardClickRequired,
      showEntityInModal:
        this.props.showEntityInModal || this.isCaseInCareSpace(obj),
      ctaIcons: this.props.ctaIcons,
      contentNotAccessible: this.props.contentNotAccessible,
      notifyParentOnDropdownToggle: this.notifyParentOnDropdownToggle,
      sourceObject: this.props.sourceObject,
      index,
      ...this.props,
    };

    if (this.props.featureNotAllowedModal)
      props['openFeatureNotAvailable'] = this.showFeatureNotAvailable;

    switch (this.props.viewType) {
      case 'Card':
        return <OsCards {...props} />;
      case 'VisitingCard':
        return <VisitingCard {...props} />;
      default:
        return <ListCards {...props} />;
    }
  }

  getPrioritizedList() {
    let { objects } = this.props,
      filteredPrioritizedObjects = [],
      filteredUnprioritizedObjects = [];

    objects.forEach((obj) =>
      obj.prioritized
        ? filteredPrioritizedObjects.push(obj)
        : filteredUnprioritizedObjects.push(obj),
    );
    return times(objects.length, (i) => {
      let prioritizedItemRequired =
        (i + 1 <= 2 || (i + 1) % 3 === 0) &&
        filteredPrioritizedObjects.length > 0;

      return prioritizedItemRequired
        ? filteredPrioritizedObjects.shift() ||
            filteredUnprioritizedObjects.shift()
        : filteredUnprioritizedObjects.shift() ||
            filteredPrioritizedObjects.shift();
    });
  }

  getFeaturedList() {
    let { objects } = this.props,
      filteredFeaturedObjects = [],
      filteredNonfeaturedObjects = [];

    objects.forEach((obj) =>
      obj.featured
        ? filteredFeaturedObjects.push(obj)
        : filteredNonfeaturedObjects.push(obj),
    );
    return times(objects.length, (i) => {
      let featuredItemRequired =
        (i + 1 <= 2 || (i + 1) % 3 === 0) && filteredFeaturedObjects.length > 0;
      return featuredItemRequired
        ? filteredFeaturedObjects.shift()
        : filteredNonfeaturedObjects.shift();
    });
  }

  getRowWiseCollection() {
    if (this.props.prioritizedIds && this.props.prioritizedIds.length > 0) {
      return this.getPrioritizedList();
    } else if (this.props.featuredIds && this.props.featuredIds.length > 0) {
      return this.getFeaturedList();
    } else {
      return this.props.objects;
    }
  }

  cardsCollection() {
    let collection = this.props.orderRowWise
      ? this.getRowWiseCollection()
      : this.getColWiseCollection();
    return this.props.onDemandLoading
      ? slice(collection, 0, this.state.cardsToRender)
      : collection;
  }

  selectedLinkIds() {
    return this.props.spaceLinks.selectedLinkIds;
  }

  isLinkSelected(card) {
    return (
      card.link && this.selectedLinkIds().includes(card.link.id.toString())
    );
  }

  isMovingLinksToNewSection() {
    return !isEmpty(this.props.spaceLinks.moveLinksToNewSectionOptions);
  }

  dragOverlaySupported() {
    return animateSupported();
  }

  renderDroppable(card, droppableRequired, id) {
    return (
      <Droppable
        id={this.getDraggableId(card, id)}
        disabled={!droppableRequired}
        className={
          droppableRequired ? 'add-new-section' : 'add-new-section-division'
        }
        onClick={() => {
          !droppableRequired && this.onSectionMarginClick(card);
        }}>
        <OrthoIcon name='add-new-section' dataHoverNotRequired={true} />
      </Droppable>
    );
  }

  getDraggableId(card, tempId = null) {
    if (this.props.tabSectionId) {
      return (
        'section:' +
        this.props.tabSectionId +
        ':link:' +
        (tempId || card.link?.id)
      );
    } else {
      return `${card.__typename}:${tempId || card.nice_id}`;
    }
  }

  getExtraCardClass(card) {
    let extraClass = '';
    if (!this.fourPerRow() && !this.props.twoPerRow) {
      extraClass += this.props.fullWidth ? ' space-full-card' : '';
      extraClass += this.props.cardsCustomMarginRequired
        ? 'space-card-col'
        : '';
    }
    return extraClass;
  }

  renderDraggableCard(card, index) {
    let id = card.link ? card.link.id : card.nice_id,
      draggableId = this.getDraggableId(card),
      isLinkSelected = this.isLinkSelected(card),
      { spaceLinks } = this.props,
      selectedLinksCount = this.selectedLinkIds().length,
      isDragTypeLink = spaceLinks.activeDragType === 'link',
      isDragging = isDragTypeLink && spaceLinks.dragId === id,
      dragOverlaySupported = this.dragOverlaySupported(),
      isDraggableHidden = dragOverlaySupported
        ? isDragTypeLink && (isLinkSelected || isDragging)
        : isDragTypeLink && isLinkSelected && !isDragging,
      leftDroppableRequired = this.props.tabSectionId
        ? isDragTypeLink && !(selectedLinksCount <= 1 && isDragging)
        : !isDragging,
      rightDroppableRequired = leftDroppableRequired,
      effectiveIndex = index + (this.props.addNewLinkAvailable ? 1 : 0),
      cardGridIdentifier = this.props.cardGridIdentifier;

    if (leftDroppableRequired) {
      let prevCard = this.cardsCollection()[index - 1],
        rightCardLeftDroppableRequired =
          prevCard &&
          (prevCard.link
            ? prevCard.link.id === spaceLinks.dragId
            : prevCard.nice_id === spaceLinks.dragId);

      if (isDragTypeLink && rightCardLeftDroppableRequired)
        leftDroppableRequired = false;

      if (isDragTypeLink && isFolderSpace(this.props.space))
        leftDroppableRequired = !this.cardsCollection().find(
          (obj) => obj.link?.id === spaceLinks.dragId,
        );
    }

    const cardBody = (
      <>
        {this.renderDroppable(card, leftDroppableRequired, 'temp' + id)}
        {dragOverlaySupported && isDragging && (
          <DragOverlay className='drag-active'>
            {selectedLinksCount > 1 && (
              <span className='drag-count'>+{selectedLinksCount - 1} more</span>
            )}
            {this.renderCardOrList(card, {}, index)}
          </DragOverlay>
        )}

        <Draggable
          key={draggableId}
          id={draggableId}
          disabled={selectedLinksCount > 0 && !isLinkSelected}
          className={isDraggableHidden ? 'hidden-drag-active' : ''}>
          {!dragOverlaySupported && isDragging && selectedLinksCount > 1 && (
            <span className='drag-count'>+{selectedLinksCount - 1} more</span>
          )}
          {this.renderCardOrList(card, {}, index)}
        </Draggable>

        {this.renderDroppable(card, leftDroppableRequired)}
      </>
    );
    let key = `${card.__typename}:${card.id}`,
      { dropdDownOpenCardHash } = this.state;
    // Note: 'temp' added in id to generate different droppable ids
    return (
      <>
        {cardGridIdentifier && (
          <OsGrid
            identifier={cardGridIdentifier}
            key={key}
            id={'link-' + id}
            extraClass={
              this.getExtraCardClass(card) +
              ((effectiveIndex + 1) % 3 === 0 ? ' next-elem-after' : '') +
              (dropdDownOpenCardHash[key] ? ' card-z-index' : '')
            }>
            {cardBody}
          </OsGrid>
        )}
        {!cardGridIdentifier && (
          <div
            key={`${card.__typename}:${card.id}`}
            id={'link-' + id}
            className={
              this.getCardClass(card) +
              ((effectiveIndex + 1) % 3 === 0 ? ' next-elem-after' : '') +
              (dropdDownOpenCardHash[key] ? ' card-z-index' : '')
            }>
            {cardBody}
          </div>
        )}

        {isDragTypeLink &&
          this.cardsCollection().length === index + 1 &&
          this.renderDroppable(
            card,
            rightDroppableRequired,
            'insert_at_bottom',
          )}
      </>
    );
  }

  sendMarginClickEvent(entityType) {
    EventTracker.marginClick(
      entityType,
      this.props.tabSectionId,
      this.props.space,
    );
  }

  onSectionMarginClick = (card) => {
    this.sendMarginClickEvent('section');
    this.props.moveCardsIntoNewSection(card);
  };

  renderWidgets(afterFeedNumber) {
    return this.props.widgetAfterListItemNumber[afterFeedNumber];
  }

  renderCardContent = (card, isSourceSpaceSectionForm, index) => {
    let effectiveIndex = index + (this.props.addNewLinkAvailable ? 1 : 0);
    return (
      <>
        {isSourceSpaceSectionForm &&
          effectiveIndex !== 0 &&
          effectiveIndex % 3 === 0 && (
            <div className='add-new-section-division visibility-hidden'>
              <OrthoIcon name='add-new-section' dataHoverNotRequired={true} />
            </div>
          )}
        {isSourceSpaceSectionForm && (
          <div className='add-new-section-division visibility-hidden'>
            <OrthoIcon name='add-new-section' dataHoverNotRequired={true} />
          </div>
        )}
        <div
          key={`${card.__typename}:${card.id}`}
          className={
            this.getCardClass(card) +
            (isSourceSpaceSectionForm && (effectiveIndex + 1) % 3 === 0
              ? ' next-elem-after'
              : '')
          }>
          {this.renderCardOrList(card, {}, index)}
        </div>
        {this.renderWidgets(index + 1)}
      </>
    );
  };

  patientRecordsSegregatedByDate = (records) => {
    let recordsList = {
      Today: {
        records: [],
      },
      Yesterday: {
        records: [],
      },
      'Earlier this week': {
        records: [],
      },
      'Last week': {
        records: [],
      },
      'More than 2 weeks ago': {
        records: [],
      },
    };

    for (let i = 0; i < records.length; i++) {
      let record = records[i];
      const lastActivityOfRecordPerformAt =
        record.care_space.last_activity_performed_at;
      if (isCurrentDay(lastActivityOfRecordPerformAt)) {
        recordsList.Today.records.push(record);
      } else if (isYesterday(lastActivityOfRecordPerformAt)) {
        recordsList.Yesterday.records.push(record);
      } else if (
        isDateInThisWeek(timeWithFormat(lastActivityOfRecordPerformAt))
      ) {
        recordsList['Earlier this week'].records.push(record);
      } else if (
        isDateInPreviousWeek(timeWithFormat(lastActivityOfRecordPerformAt))
      ) {
        recordsList['Last week'].records.push(record);
      } else {
        recordsList['More than 2 weeks ago'].records.push(record);
      }
    }
    return { recordsList, windowKeys: DAY_WINDOW_SEGREGATION };
  };
  patientRecordsSegregatedByAlphabet = (records) => {
    let recordsList = {};
    let windowKeys = [];
    for (let i = 0; i < records.length; i++) {
      let record = records[i];
      let firstLetter = record.last_name.charAt(0).toUpperCase();
      if (recordsList[firstLetter]) {
        recordsList[firstLetter].records.push(record);
      } else {
        recordsList[firstLetter] = {
          records: [record],
        };
        windowKeys.push(firstLetter);
      }
    }
    return { recordsList, windowKeys };
  };
  renderRowWiseCollection() {
    let collectionCards = this.cardsCollection();
    let isSourceSpaceSectionForm = this.props.source === 'space-section-form';
    if (PATIENT_DIRECTORY_TYPES.includes(this.props.listType)) {
      return (
        <PatientSegregationList
          collectionCards={collectionCards}
          renderCardContent={this.renderCardContent}
          sortQuery={this.props.sortQuery}
        />
      );
    }
    return collectionCards.map((card, index) => {
      if (this.props.canArrangeContent) {
        return this.renderDraggableCard(card, index);
      } else if (this.props.cardGridIdentifier) {
        return (
          <OsGrid identifier={this.props.cardGridIdentifier}>
            {this.renderCardOrList(card, {}, index)}
          </OsGrid>
        );
      } else if (this.props.isCardFadeRequired) {
        return (
          <CSSTransition
            in={!this.props.shouldCardFade(card)}
            classNames='replies-fade'
            timeout={{ exit: 500 }}
            unmountOnExit
            key={`${card.__typename}:${card.id}`}>
            {this.renderCardContent(card, isSourceSpaceSectionForm, index)}
          </CSSTransition>
        );
      } else {
        return this.renderCardContent(card, isSourceSpaceSectionForm, index);
      }
    });
  }

  fourPerRow() {
    return this.props.size === 'small-min';
  }

  notifyParentOnDropdownToggle = (card, dropdownOpen) => {
    let { dropdDownOpenCardHash } = this.state;
    dropdDownOpenCardHash[`${card.__typename}:${card.id}`] = dropdownOpen;
    this.setState({ dropdDownOpenCardHash });
  };

  getCardClass(card) {
    if (this.props.size === 'list') {
      return 'col-12 col-md-12 col-lg-12 mb-12';
    }
    if (this.fourPerRow()) {
      return 'col-12 col-md-6 col-lg-3 mb-4';
    } else if (this.props.twoPerRow) {
      return 'col-12 col-md-6 mb-4 col-sm-6 col-lg-3 ';
    } else {
      let extraClass = this.getExtraCardClass(card);
      if (this.props.fullWidth) {
        return `col-12 col-md-6 col-lg-3 mb-4 col-sm-6 space-full-card ${extraClass}`;
      } else {
        return `col-12 col-md-4 col-lg-3 mb-4 col-sm-6 ${extraClass}`;
      }
    }
  }

  renderCardsThroughOsGrid(firstCard, secondCard) {
    return (
      <OsGrid identifier={this.props.cardGridIdentifier}>
        <div className='card-stack'>
          {this.renderCardOrList(firstCard)}
          {this.renderCardOrList(secondCard)}
        </div>
      </OsGrid>
    );
  }

  renderCardThroughDiv(firstCard, secondCard) {
    return (
      <div
        key={`${firstCard.__typename}:${firstCard.id}:${secondCard.id}`}
        className={this.getCardClass()}>
        <div className='card-stack'>
          {this.renderCardOrList(firstCard)}
          {this.renderCardOrList(secondCard)}
        </div>
      </div>
    );
  }

  renderColWiseCollection(props) {
    return this.cardsCollection().map((cards, index) => {
      if (cards[0] && cards[1]) {
        return (
          <>
            {this.props.cardGridIdentifier
              ? this.renderCardsThroughOsGrid(cards[0], cards[1])
              : this.renderCardThroughDiv(cards[0], cards[1])}
            {this.renderWidgets(index + 1)}
          </>
        );
      }

      if (cards[0]) {
        let key = `${cards[0].__typename}:${cards[0].id}`,
          { dropdDownOpenCardHash } = this.state,
          className = dropdDownOpenCardHash[key] ? ' card-z-index ' : '';
        if (this.props.cardGridIdentifier) {
          return (
            <>
              <OsGrid
                identifier={this.props.cardGridIdentifier}
                extraClass={className + `grid-card`}>
                {this.renderCardOrList(cards[0], props)}
              </OsGrid>
              {this.renderWidgets(index + 1)}
            </>
          );
        } else {
          return (
            <>
              <div
                key={`${cards[0].__typename}:${cards[0].id}`}
                className={`${this.getCardClass(cards[0])} ${className}`}>
                {this.renderCardOrList(cards[0])}
              </div>
              {this.renderWidgets(index + 1)}
            </>
          );
        }
      }
      return null;
    });
  }

  isOnDemandLoadingActive() {
    return (
      this.props.onDemandLoading &&
      this.totalNumberOfCards() > this.state.cardsToRender
    );
  }

  onDemandLoadingLinkForDesktop() {
    return (
      !this.props.device.mobileDevice && (
        <div className='col-12 text-center'>
          <OsBtn
            name='BtnPrimary'
            onClick={this.loadNextBatch}
            text='Show More'
          />
        </div>
      )
    );
  }

  onDemandLoadingLinkForMobile() {
    return (
      this.props.device.mobileDevice && (
        <div className='full-width text-center see-all-mobile px-4'>
          <OsBtn
            name='BtnPrimary'
            onClick={this.toggleModal}
            text={`See All ${capitalize(this.mobileActiveTabLinkText())}`}
            htmlTag='a'
          />
        </div>
      )
    );
  }

  mobileActiveTabLinkText() {
    if (this.props.filter === 'All') {
      return '';
    } else if (this.isEducationTabActive()) {
      return this.activeFilterName();
    } else {
      return pluralizeString(this.activeFilterName());
    }
  }

  isMobileCollectionModalActive() {
    return (
      this.props.onDemandLoading &&
      this.props.device.mobileDevice &&
      this.state.showModal
    );
  }

  renderMobileCollectionModal() {
    return (
      <Modal
        show={this.state.showModal}
        animation={false}
        dialogClassName='modal-all-board-mobile'
        backdropClassName='modal-backdrop-custom'>
        <div className='modal-mobile-heading'>
          <span className='all-text-heading'>
            <OrthoIcon name='cases' dataHoverNotRequired={true} />
            <span className=''>All {this.mobileActiveTabLinkText()}</span>
          </span>
          <OrthoIcon
            name='close'
            dataHoverNotRequired={true}
            onClick={this.toggleModal}
          />
        </div>
        <div
          className={`modal-card-section ${
            this.educationalContentVisible() ? ' educational-videos' : ''
          }`}>
          {this.props.objects.map((card) => {
            return (
              <div
                key={`${card.__typename}:${card.id}`}
                className='col-12 col-md-6 col-lg-3 mb-4'>
                {this.renderCardOrList(card)}
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }

  isEducationTabActive() {
    return this.props.filter === 'Educational Content';
  }

  educationalContentVisible() {
    return includes(['All'], this.props.filter);
  }

  activeFilterName() {
    switch (this.props.filter) {
      case 'User':
        return 'Doctor';
      case 'Educational Content':
        return 'Videos & Files';
      default:
        return this.props.filter;
    }
  }

  getLoadingCardsProps() {
    return {
      className: this.getCardClass(),
      count: 15,
      initialLoad: true,
      size: this.props.size,
    };
  }

  renderCardsOrSlider() {
    if (this.props.sliderProps) {
      return (
        <Slider {...this.props.sliderProps}>{this.renderCollection()}</Slider>
      );
    } else {
      return this.renderCollection(this.props.context);
    }
  }

  render() {
    if (typeof this.props.objects == 'undefined')
      return <RenderLoadingCards {...this.getLoadingCardsProps()} />;

    var className = '';
    if (this.props.size !== 'linkCard') {
      className += 'cards row ';
    }
    if (this.educationalContentVisible()) {
      className += ' educational-videos ';
    }
    if (this.props.cardsRowClass) {
      className += this.props.cardsRowClass;
    }
    className += this.props.cardsCustomMarginRequired
      ? ' cards-custom-margin '
      : '';
    className += this.props.addNewLinkAvailable ? '' : ' without-add-new-card ';

    return (
      <div className={className} key='cards'>
        {this.renderCardsOrSlider()}

        {this.isOnDemandLoadingActive() &&
          !this.props.hideShowMore &&
          (this.onDemandLoadingLinkForDesktop() ||
            this.onDemandLoadingLinkForMobile())}

        {this.isMobileCollectionModalActive() &&
          this.renderMobileCollectionModal()}
        {this.props.featureNotAllowedModal &&
          this.state.showFeatureNotAvailableModal && (
            <GeneralModal
              showModal={true}
              content={translate('FEATURE_NOT_AVAILABLE_ON_MOBILE')}
              className='modal-general'
              svgName={'cannotEdit'}
              closeModal={() => {
                this.setState({ showFeatureNotAvailableModal: false });
              }}
            />
          )}
      </div>
    );
  }
}

RenderCards.defaultProps = {
  shouldCardFade: () => {},
  showEntityInModal: false,
  viewType: 'Card',
  widgetAfterListItemNumber: {},
  fullWidth: false,
  sourceObject: null,
  dynamicTemplates: [],
};
RenderCards = connect(({ device, spaceLinks }) => ({ device, spaceLinks }))(
  RenderCards,
);
export default RenderCards;
