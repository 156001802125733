import { invert } from 'app/utils/osLodash';

export const ACTIVITY_FEEDS_BATCH_SIZE = 5;
export const ALL_MENTIONEE_REGEX = new RegExp('@\\[User:all\\]', 'g');
export const ALPHABET_NUMBERS_REGEX = /^[a-zA-Z0-9]$/;
export const APP_NAME = 'Cloudberry';
export const ATTACHMENT_PREVIEW_URL_HASH = '#preview';
export const AUTH_ACTIONS = {
  login: 'login',
  resetPassword: 'resetPassword',
  signup: 'signup',
};
export const BLACKLISTED_URL_PATTERNS = ['..'];
export const BOARD_NAME_MAX_LENGTH = 255;
export const BOARD_DESCRIPTION_MAX_LENGTH = 280;
export const CASE_FILES_MAX_SIZE = 200 * 1024 * 1024;
export const CASE_TITLE_MAX_LENGTH = 60;
export const COMMENT_ATTACHMENT_FILE_MAX_SIZE = 25 * 1024 * 1024;
export const CONNECTION_NOT_INITIATED_STATUSES = [
  'not-connected',
  'rejected',
  'canceled',
];
export const CONVERSATION_MESSAGES_BATCH_SIZE = {
  fullView: 25,
  floater: 15,
};
export const CONVERSATIONS_BATCH_SIZE = {
  fullView: 50,
  floater: 10,
};
export const DEFAULT_FEED_TYPE = 'DefaultFeed';
export const DEMOGRAPHIC_TAGS = {
  root: [
    {
      name: 'Demographics',
      total_cases_count: 1,
      id: 'Demographics',
      visible: true,
      depth: 0,
      selectable: true,
      children: [
        {
          name: 'Gender',
          total_cases_count: 1,
          id: 'Gender',
          query_by: 'gender',
          visible: true,
          depth: 1,
          parent_id: 'Demographics',
          selectable: true,
          children: [
            {
              name: 'Male',
              total_cases_count: 1,
              id: 'Male',
              actual_id: 0,
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Gender',
              leaf: true,
              selectable: true,
            },
            {
              name: 'Female',
              total_cases_count: 1,
              id: 'Female',
              actual_id: 1,
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Gender',
              leaf: true,
              selectable: true,
            },
          ],
        },
        {
          name: 'Age',
          query_by: 'age',
          total_cases_count: 1,
          id: 'Age',
          visible: true,
          depth: 1,
          parent_id: 'Demographics',
          selectable: true,
          children: [
            {
              range_query: ['1', '5'],
              name: '1-5',
              total_cases_count: 1,
              actual_id: '-1',
              id: 'Age_1',
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Age',
              leaf: true,
              selectable: true,
            },
            {
              range_query: ['6', '10'],
              name: '6-10',
              total_cases_count: 1,
              actual_id: '-2',
              id: 'Age_2',
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Age',
              leaf: true,
              selectable: true,
            },
            {
              range_query: ['11', '15'],
              name: '11-15',
              total_cases_count: 1,
              actual_id: '-3',
              id: 'Age_3',
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Age',
              leaf: true,
              selectable: true,
            },
            {
              range_query: ['16', '20'],
              name: '16-20',
              total_cases_count: 1,
              actual_id: '-4',
              id: 'Age_4',
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Age',
              leaf: true,
              selectable: true,
            },
            {
              range_query: ['21', '25'],
              name: '21-25',
              total_cases_count: 1,
              actual_id: '-5',
              id: 'Age_5',
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Age',
              leaf: true,
              selectable: true,
            },
            {
              range_query: ['26', '200'],
              name: '>25',
              total_cases_count: 1,
              actual_id: '-6',
              id: 'Age_6',
              children: [],
              visible: true,
              depth: 2,
              parent_id: 'Age',
              leaf: true,
              selectable: true,
            },
          ],
        },
      ],
    },
  ],
};
export const DEFAULT_TARGET_COUNTRIES = ['Canada', 'United States'];
export const DRAFT_CONTEXTS = {
  comment: 'comment',
  post: 'post',
  message: 'message',
};
export const EDITOR_MENTIONEE_REGEX = new RegExp('@\\[User:editor\\]', 'g');
export const ENCRYPTION_ALGORITHM = 'aes-256-cbc';
export const ENTITIES_NOT_AVAILABLE_IN_ATTACHMENT_MODAL = ['Comment', 'Pulse'];
export const ENTITY_NAME_MAPPER = {
  'additional link': 'link',
  board: 'space',
  'person relation': 'person',
  pulse: 'post',
  user: 'doctor',
  'restricted space': 'space',
  'restricted case': 'case',
  'restricted tool': 'tool',
  'restricted user': 'user',
};
export const EXPLORE_PAGE_PATH = '/explore';
export const EXPLORE_PAGE_HEADER_HEIGHT = { web: 75, mobile: 110 };
export const EMAIL_REGEX = new RegExp(
  /^[a-z0-9.\-+_]+@([a-z0-9-_]{1,63}\.)+[a-z]{1,63}$/i,
);
export const EXTRACT_MENTIONEE_REGEX =
  /@\[User:(\d+|\w+(:\w+)?)\],\[{[^}\]]+}\]/g;
export const EXTRACT_HASH_CONSTANT = '(\\w+)';
export const EXTRACT_HASHTAG_MENTION_REGEX = new RegExp(
  `#{${EXTRACT_HASH_CONSTANT}}\s*`,
  'g',
);
export const FLOATER_MAX_TEXTAREA_HEIGHT = 85; // height of 4 lines in textarea in floater view
export const GETTING_STARTED_VIDEO_TITLE = 'Getting Started';
export const GROUPED_TOOL_PER_PAGE = 6;

export const HASH_TAG_REGEX = new RegExp(`#${EXTRACT_HASH_CONSTANT}\s*`, 'gi');
export const HIGHLIGHT_NEW_POST_TIME_LIMIT_IN_MILLISECONDS = 3000;
export const INITIAL_SEARCH_RESULT_GROUP_COUNT = 5;
export const LAST_MENTION_REGEX = /@(\w*[-\w*]*)$/;
export const LOG_ORDER = [
  'Fixed Appliance',
  'Brackets',
  'Arch Wire Upper',
  'Arch wire upper',
  'Arch Wire Lower',
  'Arch wire lower',
  'Elastics',
  'Auxiliary',
  'Auxillaries',
  'Removable',
  'Aligners',
  'Equipment',
  'Software',
];
export const MAIL_TO_SUPPORT = 'mailto:support@mycloudberry.com';
export const MAIN_NAV_SEARCH_ENTITIES = ['cases', 'tools', 'spaces', 'doctors'];
export const MAIN_AUTOSEARCH_ENTITIES = ['Case', 'User', 'Tool', 'Space'];
export const MENTION_SEPERATOR_REGEX = /\s+/;
export const MESSAGE_FLOATER_AND_FOOTER_HEIGHT = 88;
export const MESSAGE_FLOATER_HEIGHT = 48;
export const MOBILE_BACK_BUTTON_HEIGHT = 64;
export const MOBILE_HEADER_HEIGHT = 72;
export const MY_POSTS_PER_PAGE_WEB = 15;
export const NUMBERS_REGEX = /^\d+$/;
export const ORDERED_DEGREES = [
  'DDS',
  'DMD',
  'MS',
  'MSD',
  'PhD',
  'MD',
  'MPH',
  'DMSc',
  'MBA',
  'PA',
];
export const PASSWORD_INVALID_MESSAGE =
  'Password should be atleast 8 characters including 1 uppercase, 1 lowercase and 1 special character (()`~!@#$%^&*_-+=}{[]|\\:;“’<>,.?/)';
export const PASSWORD_VALIDITY_REGEX = new RegExp(
  '^(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9 ]).{8,}$',
);
export const PATH_AFTER_UNAUTHORIZATION = '/account_settings#upgrade';
export const PHONE_NUMBER_VALIDITY_REGEX = /^\d{7,15}$/;
export const PINNED_CONVERSATIONS_BATCH_SIZE = 10;
export const PLURALIZE_HASH = {
  assistcard: 'assist_cards',
  attachment: 'files',
  board: 'spaces',
  care: 'cares',
  case: 'cases',
  clinic: 'clinics',
  comment: 'comments',
  contact: 'contacts',
  doctor: 'doctors',
  file: 'files',
  folder: 'folders',
  followers: 'followers',
  insight: 'insights',
  record: 'records',
  space: 'spaces',
  user: 'users',
};

export const POLICIES_URL_HASH = ['#terms_of_use', '#privacy_policy'];
export const POLICY_LAST_MODIFIED_DATE_FORMAT = 'MMM DD, YYYY';
export const PRIVATE_ACCESS_DENIED = '/private_access_denied';
export const PROTOCOL_REGEX = /(https?:\/\/(www\.)?)/;
export const PULSE_ATTACHMENTS_MAXIMUM_LIMIT = 10;
export const RESTRICTED_OBJECT_TYPES = [
  'DeletedObject',
  'PrivateObject',
  'UnavailableObject',
];
export const RESTRICTED_TO_NONRESTRICTED_ENTITY_TYPENAME = {
  RestrictedSpace: 'Board',
};
export const RESTRICTED_USER_DEFAULT_PAGE = '/spaces';
export const SINGULARIZE_HASH = invert(PLURALIZE_HASH);
export const SEARCH_RESULTS_ORDER = {
  cases: ['cases', 'tags', 'tools', 'doctors'],
  tools: ['tools', 'tags', 'cases', 'doctors', 'companies', 'tool_tags'],
  spaces: ['spaces', 'boards', 'tags', 'cases', 'tools', 'doctors'],
  doctors: ['doctors', 'tags', 'cases', 'tools'],
  companies: ['companies'],
  comparisons: ['comparisons'],
};
export const SEARCH_RESULTS_ORDERING_ATTRIBUTE_MAPPER = {
  CaseTag: {
    tools: 'total_tools_count',
    cases: 'total_cases_count',
    spaces: 'total_boards_count',
    doctors: 'total_users_count',
  },
  Tool: {
    spaces: 'total_boards_count',
    cases: 'cases_count',
    doctors: 'users_count',
  },
  ToolTag: { all: 'total_tools_count' },
  User: {
    cases: 'total_cases_count',
    spaces: 'total_boards_count',
    tools: 'total_tools_count',
  },
};

export const SOCIAL_LINKS_ACTIVE = {
  twitter: 'https://twitter.com/helloCloudBerry',
  // linkedin: 'https://www.linkedin.com/company/qlarkhq/',
};
export const SPOTIFY_PODCAST_REGEX = /((open|play)\.spotify\.com\/)/;

export const DOWNCASE_ENTITY_NAME = {
  Attachment: 'file',
  Case: 'case',
  Company: 'company',
  Comment: 'comment',
  Comparison: 'comparison',
  Pulse: 'pulse',
  Tool: 'tool',
};
export const TIMEZONE = 'America/Tijuana';
export const URL_REGEX =
  /(https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const USER_NICE_IDENTIFIER = /@\[User:(\d+)\]/g;
export const VIEWABLE_FILE_EXTENSIONS = ['pdf', 'csv', 'xlsx', 'docx'];
export const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];
export const VIDEO_FILE_TYPES = [
  '3gp',
  'avi',
  'flv',
  'm4p',
  'm4v',
  'mkv',
  'mov',
  'mp2',
  'mp4',
  'mpe',
  'mpeg',
  'mpg',
  'mpv',
  'ogg',
  'qt',
  'swf',
  'vob',
  'webm',
  'wmv',
];
export const VERIFICATION_PROCESS_NOTION_LINK = '';
export const ZOOMABLE_FILE_EXTENSIONS = ['pdf', 'docx'];
export const GROUPS_BATCH_SIZE = 15;
export const COMMENT_ATTACHMENT_BATCH_SIZE = 4;

export const COMMENTS_ACTIVITY_TYPE = [
  'MyUnreadPatientComments',
  'MyReadPatientComments',
  'MyUnreadPartnerComments',
  'MyReadPartnerComments',
  'TeamPatientReadComments',
  'TeamPatientUnreadComments',
  'ProcessedCareSpaceComments',
  'UnprocessedCareSpaceComments',
  'ProcessedPartnerSpaceComments',
  'UnprocessedPartnerSpaceComments',
];

export const DAY_WINDOW_SEGREGATION = [
  'Today',
  'Yesterday',
  'Earlier this week',
  'Last week',
  'More than 2 weeks ago',
];

export const PATIENT_DIRECTORY_TYPES = [
  'AllPatientsOfClinic',
  'OnlyPatientsOfClinic',
  'NoResponseByPatientInCareSpaces',
  'NotAcceptedSpaces',
  'WaitingForResponseInClinic',
];
