export const MY_CASES = 'My Cases';

export const NAV_LINKS = {
  'Account Settings': { urls: ['/account_settings'], iconName: 'setting' },
  Cases: {
    urls: ['/cases', '/comparisons'],
    iconName: 'cases-fill',
    abilityObject: { action: 'index', entity: 'case', options: {} },
  },
  Members: {
    urls: ['/users'],
    iconName: 'members',
    abilityObject: { action: 'index', entity: 'user', options: {} },
  },
  Feed: {
    exactUrls: ['/'],
    urls: ['/explore'],
    mobileIcon: 'qlark-logo',
    iconName: 'home',
    abilityObject: { action: 'index', entity: 'explore', options: {} },
  },
  Folders: {
    urls: ['/folders'],
    iconName: 'folder',
    abilityEntityName: 'board',
    abilityObject: { action: 'index', entity: 'board', options: {} },
  },
  Logout: { onClickMethodName: 'logout', iconName: 'logout' },
  Messages: {
    onClickMethodName: 'onCommunicationLinkClick',
    iconName: 'messages',
    countRequired: true,
    abilityObject: { action: 'create', entity: 'conversation', options: {} },
  },
  More: {
    onClickMethodName: 'toggleNavDropdown',
    countRequired: true,
    iconName: 'more',
  },
  'My Profile': { onClickMethodName: 'openProfilePage', iconName: 'account' },
  'My Cases': { onClickMethodName: 'openMyCases', iconName: 'cases' },
  Notifications: {
    onClickMethodName: 'openNotificationPage',
    iconName: 'notification',
    countRequired: true,
  },
  'SAO 2020': { urls: [''], current_event_space: true, iconName: 'eventSpace' },
  Search: {
    onClickMethodName: 'openSearchField',
    iconName: 'search',
    abilityObject: { action: 'search', entity: 'all', options: {} },
  },
  Spaces: {
    urls: ['/spaces'],
    iconName: 'spaces',
    abilityEntityName: 'board',
    abilityObject: { action: 'index', entity: 'board', options: {} },
  },
  Support: { onClickMethodName: 'redirectToSupportChat', iconName: 'support' },
  Tools: {
    urls: ['/tools'],
    iconName: 'toolbox',
    abilityEntityName: 'tool',
    abilityObject: { action: 'index', entity: 'tool', options: {} },
  },
};

export const FEED_LINK_NAME = 'Feed';
export const WEB_NAV_LINKS = [FEED_LINK_NAME, 'Cases', 'Spaces', 'Members'];
export const MOBILE_NAV_LINKS = [
  FEED_LINK_NAME,
  'Messages',
  'SAO 2020',
  'More',
];
export const MOBILE_NAV_LINKS_WITH_SEARCH = [
  FEED_LINK_NAME,
  'Messages',
  'Search',
  'More',
];
export const MOBILE_MORE_EXPANDED_LINKS = ['Cases', 'Spaces', 'Members'];
export const MOBILE_MORE_EXPANDED_LINKS_WITH_SEARCH = [
  ...MOBILE_MORE_EXPANDED_LINKS,
  'Search',
];
export const MOBILE_MORE_EXPANDED_PROFILE_LINKS = [
  'My Profile',
  'Account Settings',
  'Support',
  'Switch workspaces',
  'Logout',
];
export const WEB_TABS_NOT_REQUIRED_FOR_PRESENTATION_MODE = ['Members'];
