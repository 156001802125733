import React from 'react';

import NotificationCardLayout from './NotificationCardLayout';
import NotificationCommentCard from './NotificationCommentCard';
import NotificationMessageCard from './NotificationMessageCard';
import SpaceCollaboratorRequestNotification from './SpaceCollaboratorRequestNotification';

export default function NotificationCard(props) {
  let newProps = {
    ...props,
    isSmallNotification: props.size === 'small-noti-dropdown',
  };

  switch (props.name) {
    case 'Liked a comment':
    case 'Commented on Case':
    case 'Commented on Space':
    case 'Commented on Comparison':
    case 'Commented on Pulse':
    case 'Mentioned in comment':
    case 'Mentioned on Pulse':
    case 'Mentioned on Space':
    case 'New Reply to a comment':
    case 'Comment Edited':
      return <NotificationCommentCard {...newProps} />;
    case 'Message Received':
      return <NotificationMessageCard {...newProps} />;
    case 'Collaborator requested to join Space':
    case 'Invite Sent to Collaborate on Space':
      return <SpaceCollaboratorRequestNotification {...newProps} />;
    default:
      return <NotificationCardLayout {...newProps} />;
  }
}
