import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import ConnectToSupport from 'app/components/ConnectToSupport';
import Icon from 'app/components/Svg';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import InterpolationTextParser from 'app/services/InterpolationTextParser';
import TRANSLATIONS from 'app/locale/en.js';

import { closeInfoModal } from 'app/actions/infoModal';
import { translate } from 'app/actions/flashMessage';
import { updateCurrentUserGraph } from 'app/actions/authentication';
import { findKey, isEqual, times } from 'app/utils/osLodash';

import {
  AUTO_CLOSE,
  ACTION_BUTTON_TEXT,
  CONTENT_KEY_MAPPER,
  DO_NOT_SHOW_CHECKBOX_ATTRIBUTE_MAPPER,
  HEADING_TEXT_MAPPER,
  ICON_NAME_MAPPER,
} from './constants';
import { Form } from 'react-final-form';
import PatientDetail from './PatientDetail';
import PartnerShareRequests from './PartnerShareRequests';
import CopyLinkSection from './CopyLinkSection';
import CopyToClipboard from 'react-copy-to-clipboard';

const UPDATE_USER_WARNING_PREFERENCE_MUTATION = queryLoader(
  'app/graphql/UpdateUserWarningPreference.gql',
);
const TYPES_NOT_CLOSE_ON_PATHCHANGE = [
  'email_verification_success',
  'email_verification_failed',
];

class InfoModal extends Component {
  state = this.defaultState();

  componentWillReceiveProps(nextProps) {
    if (
      !TYPES_NOT_CLOSE_ON_PATHCHANGE.includes(nextProps.infoModal.type) &&
      this.props.infoModal.open &&
      !isEqual(this.props.location.pathname, nextProps.location.pathname)
    )
      this.updateAndCloseModal();
  }

  componentDidUpdate(prevProps) {
    let autoClose = this.getMapperValue(AUTO_CLOSE);
    if (!prevProps.infoModal.type !== this.props.infoModal.type) {
      if (autoClose) {
        setTimeout(() => {
          this.props.closeInfoModal();
        }, autoClose.time);
      }
    }
  }

  defaultState() {
    return {
      checked: false,
      stepIndex: 1,
    };
  }

  resetState() {
    this.setState(this.defaultState());
  }

  closeModal = () => {
    if (!this.optionValue('primaryCtaRequestInProgress')) {
      this.resetState();
      this.props.closeInfoModal();
    }
  };

  renderSupportLink() {
    return <ConnectToSupport text='support team' />;
  }

  getMapperValue(mapper) {
    return (
      mapper[this.modalSource()] && mapper[this.modalSource()][this.modalType()]
    );
  }

  renderContent() {
    let translationkey = this.getMapperValue(CONTENT_KEY_MAPPER),
      translationInterploations =
        this.optionValue('contentInterpolations') || {};

    translationkey =
      translationkey && this.withSteps()
        ? translationkey[this.state.stepIndex - 1]
        : translationkey;

    if (translationkey) {
      translationInterploations = TRANSLATIONS[translationkey].includes(
        '%{support_link}',
      )
        ? {
            ...translationInterploations,
            support_link: this.renderSupportLink(),
          }
        : translationInterploations;

      if (this.props.infoModal.options.dangerouslySetInnerHTML) {
        return (
          <div
            className={`modal-info-helper-text ${
              !this.getMapperValue(HEADING_TEXT_MAPPER)
                ? `text-center ${
                    this.props.infoModal.options.isBold
                      ? 'font-weight-bold'
                      : ''
                  }`
                : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: translate(translationkey, translationInterploations),
            }}
          />
        );
      }
      return (
        <div
          className={`modal-info-helper-text ${
            !this.getMapperValue(HEADING_TEXT_MAPPER)
              ? 'text-center font-weight-bold'
              : ''
          }`}>
          {translate(translationkey, translationInterploations, false)}
        </div>
      );
    }
  }

  renderBody() {
    let patients = this.optionValue('patientDetails'),
      patientUrl = this.optionValue('patientLink'),
      loader = this.optionValue('loader'),
      partnerShareRequests = this.optionValue('partnerShareRequests');
    if (partnerShareRequests) {
      return <PartnerShareRequests requests={partnerShareRequests} />;
    } else if (patients) {
      return <PatientDetail patients={patients} />;
    } else if (patientUrl || loader) {
      return <CopyLinkSection link={patientUrl} />;
    } else {
      return null;
    }
  }

  optionValue(option) {
    return this.props.infoModal.options[option];
  }

  modalType() {
    return this.props.infoModal.type;
  }

  modalSource() {
    return this.props.infoModal.source;
  }

  goToStep(stepIndex = 1) {
    this.setState({ stepIndex });
  }

  totalSteps() {
    return this.optionValue('steps');
  }

  withSteps() {
    return !!this.totalSteps();
  }

  checkboxRequired() {
    return (
      DO_NOT_SHOW_CHECKBOX_ATTRIBUTE_MAPPER[this.modalSource()][
        this.modalType()
      ] && !this.optionValue('avoidCheckbox')
    );
  }

  headingText() {
    let headingText = this.getMapperValue(HEADING_TEXT_MAPPER),
      stepIndex = this.state.stepIndex;

    if (headingText)
      return this.withSteps()
        ? `${headingText[stepIndex - 1]} (${stepIndex}/${this.totalSteps()})`
        : InterpolationTextParser.parseText(
            headingText,
            this.optionValue('headingInterpolations'),
          );
  }

  renderHeader() {
    return <h4 className='modal-title lh-40'>{this.headingText()}</h4>;
  }

  onPrimaryActionClick = (values) => {
    let onSuccess = this.optionValue('onSuccess'),
      stepIndex = this.state.stepIndex;
    if (this.withSteps() && this.totalSteps() > stepIndex) {
      this.goToStep(stepIndex + 1);
    } else {
      onSuccess && onSuccess(values);
      !this.optionValue('closeModalNotRequiredOnPrimaryClick') &&
        this.updateAndCloseModal();
    }
  };

  onSecondaryActionClick = () => {
    let closeModal = this.optionValue('closeModal'),
      onSecondaryActionClick = this.optionValue('onSecondaryActionClick');
    if (this.withSteps()) {
      this.goToStep(this.state.stepIndex - 1);
    } else {
      if (onSecondaryActionClick) {
        onSecondaryActionClick();
      } else if (closeModal) {
        closeModal();
      }
      this.updateAndCloseModal();
    }
  };

  onCloseModal = () => {
    let closeModal = this.optionValue('closeModal');
    closeModal && closeModal();
    this.updateAndCloseModal();
  };

  isFooterCenterRequired() {
    return false;
  }

  renderActions() {
    let patientUrl = this.optionValue('patientLink');
    let actionButton = this.getMapperValue(ACTION_BUTTON_TEXT),
      primaryButtonText =
        (this.optionValue('contentInterpolations') &&
          this.optionValue('contentInterpolations')['primary_button_text']) ||
        actionButton
          ? actionButton['primary']
          : '',
      secondaryButtonText = actionButton ? actionButton['secondary'] : '',
      secondaryButtonNotRequired =
        this.withSteps() && this.state.stepIndex === 1,
      primaryButtonLoaderRequired = this.optionValue(
        'primaryCtaRequestInProgress',
      ),
      primaryButtonExtraClass = `web-view-btn btn-${primaryButtonText} ${
        primaryButtonLoaderRequired ? 'disabled' : ''
      }`,
      primaryButtonProps = {
        type: 'submit',
        text: primaryButtonText,
        htmlTag: 'button',
        name: 'BtnPrimary',
        loaderRequired: primaryButtonLoaderRequired,
        extraClass: secondaryButtonText
          ? `${primaryButtonExtraClass} ms-3`
          : primaryButtonExtraClass,
      },
      secondaryButtonProps = {
        text: secondaryButtonText,
        onClick: this.onSecondaryActionClick,
        name: 'BtnIcon',
        extraClass: 'web-view-btn px-3',
      },
      footerActionClass = 'm-0 ',
      secondaryActionComponentPresent = !!this.optionValue(
        'secondaryActionComponent',
      );

    footerActionClass += secondaryButtonText ? ' ' : ' footer-btn-center pt-4';
    footerActionClass += this.isFooterCenterRequired()
      ? ' footer-btn-center-course'
      : '';

    return (
      <div className={footerActionClass}>
        {secondaryActionComponentPresent &&
          this.optionValue('secondaryActionComponent')}
        {!secondaryActionComponentPresent &&
          !secondaryButtonNotRequired &&
          secondaryButtonText && <OsBtn {...secondaryButtonProps} />}
        {primaryButtonText && !patientUrl && <OsBtn {...primaryButtonProps} />}
        {primaryButtonText && patientUrl && (
          <CopyToClipboard text={patientUrl}>
            <OsBtn {...primaryButtonProps} />
          </CopyToClipboard>
        )}
      </div>
    );
  }

  renderIcon() {
    let iconKey = `${this.modalSource()}:${this.modalType()}`,
      iconName;

    if (this.withSteps()) iconKey += ':' + this.state.stepIndex;

    iconName = findKey(ICON_NAME_MAPPER, (values) => values.includes(iconKey));

    if (iconName) {
      return (
        <div className='my-3 text-center'>
          <Icon name={iconName} />
        </div>
      );
    }
  }

  updateAndCloseModal = () => {
    if (this.checkboxRequired() && this.state.checked) {
      let userAttributeDetails =
          DO_NOT_SHOW_CHECKBOX_ATTRIBUTE_MAPPER[this.modalSource()][
            this.modalType()
          ],
        attribute = userAttributeDetails.attribute,
        value = userAttributeDetails.value,
        userUpdatedData = {};

      userUpdatedData[attribute] = value;
      this.props.updateCurrentUserGraph(userUpdatedData);

      this.props.updateUserWarningPreferenceMutation({
        variables: {
          attribute: attribute,
          value: value,
        },
      });
    }

    let onClose = this.optionValue('onClose');
    onClose && onClose();

    this.closeModal();
  };

  toggleInput = (e) => {
    this.setState({ checked: e.target.checked });
  };

  renderDoNotShowAgainCheckbox() {
    if (this.checkboxRequired()) {
      return (
        <div className='os-checkbox-block'>
          <label className='os-checkbox-label'>
            <input
              className='os-checkbox'
              type='checkbox'
              checked={this.state.checked}
              onChange={this.toggleInput}
            />
            <OrthoIcon name='checkbox-default' dataHoverNotRequired={true} />
            <OrthoIcon name='checkbox' dataHoverNotRequired={true} />
            {translate('DONT_SHOW_THIS_MESSAGE_AGAIN')}
          </label>
        </div>
      );
    }
  }

  renderStepsNav() {
    return (
      <ul className='modal-space-type-list'>
        {times(this.totalSteps(), (i) => {
          return (
            <li
              className={this.state.stepIndex === i + 1 ? 'active' : ''}
              onClick={this.goToStep.bind(this, i + 1)}
            />
          );
        })}
      </ul>
    );
  }

  closeIconRequired() {
    return (
      !this.getMapperValue(AUTO_CLOSE) &&
      !this.optionValue('closeIconNotRequired')
    );
  }

  isAllUnchecked(values) {
    return (
      !(Object.keys(values).length === 0) &&
      Object.keys(values).every((k) => !values[k])
    );
  }

  render() {
    if (!this.props.infoModal.open) return null;

    let footerClass = 'modal-footer p-0 mt-4 ';
    footerClass += this.props.device.mobileDevice ? 'pt-3 ' : '';

    return (
      <Modal
        show={this.props.infoModal.open}
        onHide={this.onCloseModal}
        animation={false}
        dialogClassName={`os-grid-modal space-warning-modal ${this.modalType()}`}
        backdropClassName='modal-backdrop-custom add-pulse-backdrop top-priority-modal'>
        <Modal.Header className='border-0 modal-header p-0 m-0 d-flex align-items-center'>
          {this.renderHeader()}
          {this.closeIconRequired() && (
            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn r-0'
              icon='close'
              label='Close modal'
              onClick={this.onCloseModal}
            />
          )}
        </Modal.Header>
        <Form
          onSubmit={this.onPrimaryActionClick}
          initialValues={this.optionValue('initialValue')}
          validate={(values) => {
            const isNotChecked = this.isAllUnchecked(values);
            const errors = {};
            if (isNotChecked) {
              Object.keys(values).forEach(
                (field) => (errors[field] = 'Required'),
              );
              return errors;
            } else return {};
          }}
          render={(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <Modal.Body className='modal-body p-0'>
                  {this.renderIcon()}
                  {this.withSteps() && this.renderStepsNav()}
                  {this.renderContent()}
                  {this.renderBody()}
                  {this.renderDoNotShowAgainCheckbox()}
                </Modal.Body>
                <Modal.Footer className={footerClass}>
                  {this.renderActions()}
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Modal>
    );
  }
}

InfoModal = withRouter(InfoModal);
InfoModal = graphql(UPDATE_USER_WARNING_PREFERENCE_MUTATION, {
  name: 'updateUserWarningPreferenceMutation',
})(InfoModal);
InfoModal = connect(({ device, infoModal }) => ({ device, infoModal }), {
  closeInfoModal,
  updateCurrentUserGraph,
})(InfoModal);
export default InfoModal;
