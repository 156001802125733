export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const RESET = 'RESET';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const REFRESH_CURRENT_USER = 'REFRESH_CURRENT_USER';

export const UPDATE_CURRENT_USER_GRAPH = 'UPDATE_CURRENT_USER_GRAPH';
export const UPDATE_CURRENT_USER_TOKEN = 'UPDATE_CURRENT_USER_TOKEN';

export const SET_NAV_SEARCH_QUERY = 'SET_NAV_SEARCH_QUERY';
export const SET_NAV_SEARCH_TOTAL = 'SET_NAV_SEARCH_TOTAL';
export const SET_NAV_SEARCH_CATEGORY = 'SET_NAV_SEARCH_CATEGORY';

export const MERGE_CURRENT_WORKSPACE_DATA = 'MERGE_CURRENT_WORKSPACE_DATA';
export const MERGE_ATTRIBUTES_IN_ACTIVE_CONVERSATION =
  'MERGE_ATTRIBUTES_IN_ACTIVE_CONVERSATION';
export const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION';

export const VIDEO_CHANGE_STATE = 'VIDEO_CHANGE_STATE';
export const VIDEO_PROGRESS = 'VIDEO_PROGRESS';
export const VIDEO_CHANGE_TYPE = 'VIDEO_CHANGE_TYPE';
export const VIDEO_CHANGE_MODE = 'VIDEO_CHANGE_MODE';
export const VIDEO_CHANGE_IMAGE = 'VIDEO_CHANGE_IMAGE';
export const VIDEO_HASH_CHANGE = 'VIDEO_HASH_CHANGE';
export const RESET_VIDEO_DATA = 'RESET_VIDEO_DATA';

export const ASSIGN_ADD_OBJECTS = 'ASSIGN_ADD_OBJECTS';
export const ASSIGN_CONSUME = 'ASSIGN_CONSUME';
export const ASSIGN_MESSAGE = 'ASSIGN_MESSAGE';
export const ASSIGN_TYPE = 'ASSIGN_TYPE';
export const ASSIGN_CREATE = 'ASSIGN_CREATE';
export const ASSIGN_REMOVE_OBJECTS = 'ASSIGN_REMOVE_OBJECTS';
export const ASSIGN_OBJETS_AND_CREATE = 'ASSIGN_OBJETS_AND_CREATE';

export const COMMENT_SHARE_OPEN = 'COMMENT_SHARE_OPEN';
export const COMMENT_SHARE_CHANGE_TYPE = 'COMMENT_SHARE_CHANGE_TYPE';
export const COMMENT_SHARE_ASSIGN = 'COMMENT_SHARE_ASSIGN';
export const COMMENT_SHARE_CONSUME = 'COMMENT_SHARE_CONSUME';

export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';

export const SET_CURRENT_SPIN_INDEX = 'SET_CURRENT_SPIN_INDEX';
export const SET_SYSTEM_CONFIG = 'SET_SYSTEM_CONFIG';

export const DEVICE_VIEW = 'DEVICE_VIEW';
export const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';

export const DO_NOTHING = 'DO_NOTHING';

export const SET_NAV_SEARCH_OPEN = 'SET_NAV_SEARCH_OPEN';
export const SET_NAV_SEARCH_CLOSE = 'SET_NAV_SEARCH_CLOSE';

export const OPEN_UPGRADE_MODAL = 'OPEN_UPGRADE_MODAL';
export const CLOSE_UPGRADE_MODAL = 'CLOSE_UPGRADE_MODAL';

export const OPEN_PLAYER_MODAL = 'OPEN_PLAYER_MODAL';
export const CLOSE_PLAYER_MODAL = 'CLOSE_PLAYER_MODAL';

export const SET_CURRENT_USER_SESSION_VALID = 'SET_CURRENT_USER_SESSION_VALID';

export const SET_CONNECT_MODAL_OPEN = 'SET_CONNECT_MODAL_OPEN';
export const SET_CONNECT_MODAL_CLOSE = 'SET_CONNECT_MODAL_CLOSE';

export const ACTION_BY_GUEST_USER = 'ACTION_BY_GUEST_USER';
export const UPDATE_GUEST_USER = 'UPDATE_GUEST_USER';
export const UPDATE_GUEST_USER_DATA = 'UPDATE_GUEST_USER_DATA';

export const OPEN_PASSWORD_UPDATE_MODAL = 'OPEN_PASSWORD_UPDATE_MODAL';
export const CLOSE_PASSWORD_UPDATE_MODAL = 'CLOSE_PASSWORD_UPDATE_MODAL';

export const SET_NEW_POST = 'SET_NEW_POST';
export const RESET_NEW_POST = 'RESET_NEW_POST';

export const OPEN_POLICIES_MODAL = 'OPEN_POLICIES_MODAL';
export const CLOSE_POLICIES_MODAL = 'CLOSE_POLICIES_MODAL';

export const OPEN_SUBSCRIPTION_MODAL = 'OPEN_SUBSCRIPTION_MODAL';
export const CLOSE_SUBSCRIPTION_MODAL = 'CLOSE_SUBSCRIPTION_MODAL';

export const OPEN_ATTACHMENT_PREVIEW = 'OPEN_ATTACHMENT_PREVIEW';
export const CLOSE_ATTACHMENT_PREVIEW = 'CLOSE_ATTACHMENT_PREVIEW';

export const OPEN_NOTE_VIEW_MODAL = 'OPEN_NOTE_VIEW_MODAL';
export const CLOSE_NOTE_VIEW_MODAL = 'CLOSE_NOTE_VIEW_MODAL';

export const SET_ONBOARDING_TIP_REF = 'SET_ONBOARDING_TIP_REF';
export const SET_ONBOARDING_TIP_VISIBILITY = 'SET_ONBOARDING_TIP_VISIBILITY';
export const UNSET_ONBOARDING_TIP = 'UNSET_ONBOARDING_TIP';

export const CLOSE_INFO_MODAL = 'CLOSE_INFO_MODAL';
export const OPEN_INFO_MODAL = 'OPEN_INFO_MODAL';
export const UPDATE_INFO_MODAL = 'UPDATE_INFO_MODAL';

export const ALL_PATIENT_LIST_FILTER = 'ALL_PATIENT_LIST_FILTER';
export const NOT_ACCEPTED_PATIENT_LIST_FILTER =
  'NOT_ACCEPTED_PATIENT_LIST_FILTER';
export const NO_RESPONSE_PATIENT_LIST_FILTER =
  'NO_RESPONSE_PATIENT_LIST_FILTER';
export const WAITING_FOR_RESPONSE_FILTER = 'WAITING_FOR_RESPONSE_FILTER';

export const CLOSE_TASK_MODAL = 'CLOSE_TASK_MODAL';
export const OPEN_TASK_MODAL = 'OPEN_TASK_MODAL';

export const SET_ACTIVE_RESOURCE = 'SET_ACTIVE_RESOURCE';
export const SET_ACTIVE_RESOURCE_PATIENT = 'SET_ACTIVE_RESOURCE_PATIENT';
export const SET_ACTIVE_RESOURCE_AFTER_SHARING =
  'SET_ACTIVE_RESOURCE_AFTER_SHARING';

export const OPEN_SHARE_MODAL = 'OPEN_SHARE_MODAL';
export const CLOSE_SHARE_MODAL = 'CLOSE_SHARE_MODAL';

export const VIEW_OBJECT = 'VIEW_OBJECT';

export const CLOSE_ENTITY_PREVIEW_MODAL = 'CLOSE_ENTITY_PREVIEW_MODAL';
export const OPEN_ENTITY_PREVIEW_MODAL = 'OPEN_ENTITY_PREVIEW_MODAL';

export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';

export const OPEN_EDIT_IMAGE_MODAL = 'OPEN_EDIT_IMAGE_MODAL';
export const CLOSE_EDIT_IMAGE_MODAL = 'CLOSE_EDIT_IMAGE_MODAL';

export const IS_DIRECT_WORKSPACE_LOGIN = 'IS_DIRECT_WORKSPACE_LOGIN';
export const NOT_DIRECT_WORKSPACE_LOGIN = 'NOT_DIRECT_WORKSPACE_LOGIN';

export const MOVE_SPACE_LINKS_TO_NEW_SECTION =
  'MOVE_SPACE_LINKS_TO_NEW_SECTION';
export const RESET_ACTIVE_DRAG_DETAILS = 'RESET_ACTIVE_DRAG_DETAILS';
export const RESET_SELECTED_SPACE_LINKS = 'RESET_SELECTED_SPACE_LINKS';
export const SET_ACTIVE_DRAG_DETAILS = 'SET_ACTIVE_DRAG_DETAILS';
export const SET_NEW_SECTION_ID = 'SET_NEW_SECTION_ID';
export const SET_SPACE_LINKS_DROP_OPTIONS = 'SET_SPACE_LINKS_DROP_OPTIONS';
export const TOGGLE_SELECTED_SPACE_LINK = 'TOGGLE_SELECTED_SPACE_LINK';
export const TOGGLE_SPACE_LINKS_REQUEST_IN_PROGRESS =
  'TOGGLE_SPACE_LINKS_REQUEST_IN_PROGRESS';

export const ADD_UNIVERSAL_BANNER = 'ADD_UNIVERSAL_BANNER';
export const UNIVERSAL_BANNER_HIDE = 'UNIVERSAL_BANNER_HIDE';
export const UNIVERSAL_BANNER_SHOW = 'UNIVERSAL_BANNER_SHOW';
export const REMOVE_UNIVERSAL_BANNER = 'REMOVE_UNIVERSAL_BANNER';

export const UPDATE_DRAFT = 'UPDATE_DRAFT';
export const CLEAR_DRAFT = 'CLEAR_DRAFT';

export const UPDATE_BANNER_VALUES = 'UPDATE_BANNER_VALUES';

export const UPDATE_SPACE_STATE = 'UPDATE_SPACE_STATE';

export const TOOGLE_WORKSPACE_DROPDOWN_STATE =
  'TOOGLE_WORKSPACE_DROPDOWN_STATE';
export const UPDATE_CURRENT_WORKSPACE_DATA = 'UPDATE_CURRENT_WORKSPACE_DATA';
export const UPDATE_GUEST_WORKSPACE_OWNER_DATA =
  'UPDATE_GUEST_WORKSPACE_OWNER_DATA';
export const UPDATE_PASSWORD_PRESENT_FIELD = 'UPDATE_PASSWORD_PRESENT_FIELD';
export const SET_FLAGGED_FEATURES = 'SET_FLAGGED_FEATURES';

export const OPEN_SIGNUP_MODAL = 'OPEN_SIGNUP_MODAL';
export const CLOSE_SIGNUP_MODAL = 'CLOSE_SIGNUP_MODAL';

export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';

export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';
export const VERIFY_USER_EMAIL_BY_TOKEN = 'VERIFY_USER_EMAIL_BY_TOKEN';
export const FORGOT_PASSWORD_MAIL = 'FORGOT_PASSWORD_MAIL';

export const OPEN_ONBOARDING_VERIFICATION_MODALS =
  'OPEN_ONBOARDING_VERIFICATION_MODALS';
export const CLOSE_ONBOARDING_VERIFICATION_MODALS =
  'CLOSE_ONBOARDING_VERIFICATION_MODALS';

export const DISABLE_PRESENTATION_MODE = 'DISABLE_PRESENTATION_MODE';
export const ENABLE_PRESENTATION_MODE = 'ENABLE_PRESENTATION_MODE';

export const RESET_STATE = 'RESET_STATE';
export const SET_CURRENT_SLIDE = 'SET_CURRENT_SLIDE';
export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
export const SET_MODE_TYPE = 'SET_MODE_TYPE';
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';

export const RESET_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN =
  'RESET_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN';
export const OPEN_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN =
  'OPEN_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN';

export const SET_LECTURE_ENTITY = 'SET_LECTURE_ENTITY';
export const SET_ENTITY_IS_LOADING = 'SET_ENTITY_IS_LOADING';
export const SET_RIGHT_SECTION_VISIBILITY = 'SET_RIGHT_SECTION_VISIBILITY';
export const SET_CLV_MOVEMENT_DIRECTION = 'SET_CLV_MOVEMENT_DIRECTION';

export const OPEN_PATIENT_MODAL = 'OPEN_PATIENT_MODAL';
export const CLOSE_PATIENT_MODAL = 'CLOSE_PATIENT_MODAL';

export const FOLLOW_OBJECT = 'FOLLOW_OBJECT';

export const CARE_SPACE_ACTIONS = 'CARE_SPACE_ACTIONS';

export const ADD_CONTACT_PARTY = 'ADD_CONTACT_PARTY';
export const CLOSE_CONTACT_PARTY_MODAL = 'CLOSE_CONTACT_PARTY_MODAL';
export const EDIT_CONTACT_PARTY = 'EDIT_CONTACT_PARTY';

export const OPEN_DISABLE_CARE_SPACE_MODAL = 'OPEN_DISABLE_CARE_SPACE_MODAL';
export const CLOSE_DISABLE_CARE_SPACE_MODAL = 'CLOSE_DISABLE_CARE_SPACE_MODAL';

export const REQUEST_SERVED = 'REQUEST_SERVED';
export const SET_SOURCE = 'SET_SOURCE';

export const WIDGET_COLLAPSED = 'WIDGET_COLLAPSED';
export const WIDGET_EXPANDED = 'WIDGET_EXPANDED';

export const UNLOCK_NAV = 'UNLOCK_NAV';
export const LOCK_NAV = 'LOCK_NAV';

export const OPEN_TEAMSPACE_MODAL = 'OPEN_TEAMSPACE_MODAL';
export const CLOSE_TEAMSPACE_MODAL = 'CLOSE_TEAMSPACE_MODAL';

export const OPEN_FOLDER_MODAL = 'OPEN_FOLDER_MODAL';
export const CLOSE_FOLDER_MODAL = 'CLOSE_FOLDER_MODAL';

export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
export const OPEN_PARTNER_SPACE_MODAL = 'OPEN_PARTNER_SPACE_MODAL';
export const CLOSE_PARTNER_SPACE_MODAL = 'CLOSE_PARTNER_SPACE_MODAL';
export const SET_PARTNER_SPACE_LABEL = 'SET_PARTNER_SPACE_LABEL';
export const CLOSE_TASK_PREVIEW_MODAL = 'CLOSE_TASK_PREVIEW_MODAL';
export const OPEN_TASK_PREVIEW_MODAL = 'OPEN_TASK_PREVIEW_MODAL';

export const SET_GLOBAL_SEARCH_QUERY = 'SET_GLOBAL_SEARCH_QUERY';
