import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import scrollToElement from 'scroll-to-element';

import AdditionalLinkWrapper from './AdditionalLinkWrapper';
import AttachmentCard from './AttachmentCard';
import CommentCard from './CommentCard';
import ConversationMessageCard from './ConversationMessageCard';
import DeletedCard from './DeletedCard';
import DynamicCard from './DynamicCard';
import PersonCard from './PersonCard';
import PersonTableRow from './PersonCard/TableRow';
import PrivateCard from './PrivateCard';
import SpaceCard from './SpaceCard';
import SpaceLinkCard from './SpaceLinkCard';
import UnavailableCard from './UnavailableCard';
import UserCard from './UserCard';

import EventTracker from 'app/services/EventTracker';

import { CARD_ACTION_CONTEXT } from 'app/components/OsCards/AbstractCard';
import {
  entityUrl,
  entityIsAdditionalLink,
  isCardViewed,
} from 'app/utils/entitiesHelper';
import { addClassToElement, removeClassFromElement } from 'app/utils/domHelper';
import { isPodcastURL, isVideo } from 'app/utils/fileHelper';
import { cardBackgroundColor, cardSize } from 'app/utils/osCardHelper';
import { isViewedOverlayRequired } from 'app/utils/spaceHelper';
import { parameterize } from 'app/utils/stringHelper';
import {
  addProtocolIfNotPresent,
  isSameDomainSupportedType,
  isUrlOfSameDomain,
} from 'app/utils/urlHelper';
import { isEmpty, lowerCase, omit } from 'app/utils/osLodash';
import { isGuestUser } from 'app/utils/userHelper';
import { getDynamicObjFromKey } from 'app/utils/dynamicTemplateHelper';

import { openPlayerModal } from 'app/actions/playerModal';
import { openUpgradeModal } from 'app/actions/upgrade';
import { setActiveResource } from 'app/actions/activeResource';
import { viewObject } from 'app/actions/viewObject';
import { authModal } from 'app/actions/authentication';
import { openInfoModal } from 'app/actions/infoModal';

import { ENTITY_NAME_MAPPER } from 'app/constants';
import PartnerSpaceCard from 'app/components/OsCards/SpaceCard/PartnerSpaceCard';

const SPACE_SEPARATE_CARD_TYPES = ['Care', 'Clinic'];
const VIEWABLE_COUNT_ENTITIES = [
  'AdditionalLink',
  'Attachment',
  'Board',
  'Case',
  'Comment',
  'Comparison',
  'Company',
  'Tool',
  'User',
];
export const SUPPORTED_ENTITIES_IN_PREVIEW_MODAL = {
  Case: 'CASE_DETAIL',
};

const LINK_NOT_SUPPORTED_ENTITIES = ['Person', 'PersonRelation'];

class OsCards extends Component {
  allowDefaultBehavior = () => {
    return !!this.props.allowDefaultBehavior;
  };

  onCardClick = (e, options = {}) => {
    //insception due to additional link wrapper
    if (!this.allowDefaultBehavior) {
      e.preventDefault && e.preventDefault();
      e.stopPropagation && e.stopPropagation();
    }

    let obj = this.getObject(),
      queryType = SUPPORTED_ENTITIES_IN_PREVIEW_MODAL[obj.__typename];

    if (this.props.closeRequired) {
      return; // Do nothing
    } else if (false && obj.restricted) {
      this.props.openUpgradeModal();
    } else if (
      this.props.multiSelectMode &&
      !this.isSelected() &&
      this.isSourceSpaceDetail() &&
      this.context.onCardSelectClick
    ) {
      this.context.onCardSelectClick(obj);
    } else if (this.props.onCardClick) {
      this.props.onCardClick(obj);
    } else if (
      !isSameDomainSupportedType(obj) &&
      !isUrlOfSameDomain(obj.frontend_url)
    ) {
      window.location.href = obj.frontend_url;
    } else {
      let url = options.url || this.getObjectUrl(),
        isAdditionalLink = entityIsAdditionalLink(obj),
        openPlayerModal =
          isAdditionalLink && (obj.playable || isPodcastURL(obj)),
        openInNewTabRequired = isAdditionalLink || this.props.openInNewTab;

      if (this.props.contentNotAccessible) {
        this.contentNotAccessibleFlow();
      } else {
        if (openPlayerModal) {
          this.props.openPlayerModal(
            { ...obj },
            { autoplay: true, nodownload: true, unmuted: true },
          );
        } else if (openInNewTabRequired) {
          window.open(addProtocolIfNotPresent(url), '_blank');
        } else if (url) {
          this.props.navigate(url, this.props.urlPushState || {});
        }
      }

      this.props.onEntityShow && this.props.onEntityShow();
      this.trackEntityShow();

      if (!openInNewTabRequired && obj.__typename === 'Board')
        this.props.setActiveResource(obj);

      !isGuestUser() && this.sendViewObjectRequestIfRequired(obj);
    }
  };

  isPreviewListing() {
    return this.context.contentPreviewListing;
  }

  setLectureEntity() {
    let lectureEntity = this.props.lectureView?.entity?.linkable,
      { obj } = this.props;

    if (
      lectureEntity &&
      lectureEntity.id === obj.id &&
      lectureEntity.__typename === obj.__typename
    ) {
      let clvContainer = document.getElementById('space-clv');
      clvContainer &&
        scrollToElement(clvContainer, {
          ease: 'linear',
          offset: -2000,
          duration: 1000,
        });
    } else {
      this.props.setLectureEntity({ ...obj.link, linkable: obj });
    }
  }

  contentNotAccessibleFlow() {
    if (isGuestUser()) {
      this.openSignupModal();
    } else {
      this.props.openInfoModal('space', 'content_not_accessible');
    }
  }

  isSourceSpaceDetail() {
    return this.context.source === 'space-detail';
  }

  isViewedOverlayRequired() {
    return isViewedOverlayRequired(this);
  }

  openSignupModal() {
    this.props.authModal({ authModal: 'signup' });
  }

  sendViewObjectRequestIfRequired(obj) {
    if (
      VIEWABLE_COUNT_ENTITIES.includes(obj.__typename) &&
      this.context.source === 'space-detail' &&
      !isEmpty(obj.link)
    ) {
      let link_obj = {
        id: obj.link.id,
        __typename: 'BoardLink',
        view_count: 0,
      };
      this.props.viewObject({ obj: link_obj });
    }
  }

  getObject() {
    let { obj } = this.props;
    if (['DynamicTemplate'].includes(obj?.__typename)) {
      return getDynamicObjFromKey(obj.id, this.props.currentUser.graph);
    } else {
      return obj || {};
    }
  }

  trackEntityShow() {
    EventTracker.trackEntityShow(this.getObject());
  }

  getCardSize() {
    return cardSize({
      width: this.props.device.width,
      mobileDeviceLandscape: this.props.device.mobileDeviceLandscape,
      size: this.props.size,
    });
  }

  isAuthorOrEditorOfSourceObject() {
    return (
      this.context.sourceObject && this.context.sourceObject.is_author_or_editor
    );
  }

  isLectureCardOverlayReqiured() {
    let { obj } = this.props;
    return (
      isViewedOverlayRequired(this) &&
      obj.link &&
      obj.link.viewed_space_content &&
      !this.isAuthorOrEditorOfSourceObject()
    );
  }

  containerClasses() {
    let obj = this.getObject(),
      typename = obj.__typename,
      generic = `os-card-v2 ${
        isCardViewed(this.getObject().link, this.context.sourceObject)
          ? 'card-viewed'
          : ''
      } `,
      cardSpecific = '',
      type = parameterize(
        lowerCase(ENTITY_NAME_MAPPER[lowerCase(typename)] || typename),
      );

    if (typename === 'Attachment' && isVideo(obj)) {
      type = 'video';
    } else if (typename === 'User') {
      type = 'user';
    } else if (typename === 'AdditionalLink') {
      type = 'attachment';
    } else if (['Board', 'RestrictedSpace'].includes(typename)) {
      if (obj.category_type === 'learning_hub') {
        type = 'learning-hub';
      } else if (SPACE_SEPARATE_CARD_TYPES.includes(obj.type)) {
        type = lowerCase(obj.type);
      }
    } else if (typename === 'FolderSpace') {
      type = 'space';
    }

    generic += cardBackgroundColor(obj);

    switch (this.getCardSize()) {
      case 'tall':
        generic += 'tall-card';
        cardSpecific += `os-lg-${type}`;
        break;
      case 'tall-min':
        generic += 'tall-card tall-min-card';
        cardSpecific += `os-lg-${type} ${type}-304`;
        break;
      case 'small':
        generic += 'small-card';
        cardSpecific += `os-sm-${type}`;
        break;
      case 'small-min':
        generic += 'small-card small-min-card';
        cardSpecific += `os-sm-${type} ${type}-240`;
        break;

      case 'extra-small':
        generic += 'extra-small-card extra-small-min-card';
        cardSpecific += `os-sm-${type}`;
        break;
      default:
    }

    generic += this.props.closeRequired ? ' with-cross ' : '';
    generic += this.props.selectionMode ? ' selection-mode ' : '';
    generic += this.props.selected ? ' selected ' : '';
    generic += this.props.insideModal ? ' inside-modal ' : '';
    generic += this.props.multiSelectMode ? ' multi-selection-mode ' : '';
    generic += this.props.avoidModalActions ? ' avoid-modal-actions ' : '';
    generic += this.props.disableCard ? ' pointer-none ' : '';
    generic += this.isLectureCardOverlayReqiured() ? ' lecture-card' : '';
    generic += this.isDynamicWidthCard() ? ' dynamic-width-card' : '';

    return { generic, cardSpecific };
  }

  isSmall() {
    return ['small', 'small-min', 'extra-small'].includes(this.getCardSize());
  }

  isSelected() {
    let obj = this.getObject();
    return (
      this.props.selected ||
      (this.props.multiSelectMode &&
        obj.link &&
        this.props.spaceLinks.selectedLinkIds.includes(obj.link.id.toString()))
    );
  }

  cardProps() {
    return {
      ...this.props,
      cardClickRequired: this.props.cardClickRequired,
      containerClasses: this.containerClasses().cardSpecific,
      obj: this.getObject(),
      onCardClick: this.onCardClick,
      onDropDownToggle: this.onDropDownToggle,
      selected: this.isSelected(),
      size: this.getCardSize(),
    };
  }

  onDropDownToggle = (dropdownOpen) => {
    if (isGuestUser()) {
      this.openSignupModal();
    } else {
      let parentElement = this.container.parentElement;
      if (parentElement) {
        let method = dropdownOpen ? addClassToElement : removeClassFromElement;
        method('card-z-index', parentElement);
        this.props.notifyParentOnDropdownToggle(this.getObject(), dropdownOpen);
      }
    }
  };

  isDynamicWidthCard() {
    return this.props.size === 'thumbnail' || this.props.size === 'big';
  }

  renderCard() {
    let cardProps = this.cardProps();
    switch (this.getObject().__typename) {
      case 'AdditionalLink':
        return <AdditionalLinkWrapper {...cardProps} />;
      case 'Attachment':
        return <AttachmentCard {...cardProps} />;
      case 'BoardLink':
      case 'RestrictedSpaceLink':
        return <SpaceLinkCard {...cardProps} />;
      case 'Comment':
        return <CommentCard {...cardProps} />;
      case 'ConversationMessage':
        return <ConversationMessageCard {...cardProps} />;
      case 'DeletedObject':
        return <DeletedCard {...cardProps} />;
      case 'DynamicTemplate':
        return <DynamicCard {...cardProps} />;
      case 'Person':
        if (cardProps.size === 'list') {
          return <PersonTableRow {...cardProps} />;
        } else {
          return <PersonCard {...cardProps} />;
        }
      case 'PersonRelation':
        return (
          <OsCards
            {...this.props}
            obj={{
              ...cardProps.obj.related_person,
              relation: cardProps.obj.relation,
              notify: cardProps.obj.notify,
              person_connection_id: cardProps.obj.id,
            }}
          />
        );
      case 'PrivateObject':
        return <PrivateCard {...cardProps} />;
      case 'Board':
      case 'FolderSpace':
      case 'Space':
        return <SpaceCard {...cardProps} />;
      case 'PartnerSpace':
        return <PartnerSpaceCard {...cardProps} />;
      case 'UnavailableObject':
        return <UnavailableCard {...cardProps} />;
      case 'User':
      case 'RestrictedUser':
        return <UserCard {...cardProps} />;
      case 'Video':
        return <AttachmentCard {...cardProps} />;
      default:
        return null;
    }
  }

  objSupportLink() {
    return !LINK_NOT_SUPPORTED_ENTITIES.includes(this.getObject().__typename);
  }

  isLinkRequired() {
    let obj = this.getObject();
    return (
      !this.props.closeRequired &&
      !obj.restricted &&
      !this.props.onCardClick &&
      this.objSupportLink()
    );
  }

  getObjectUrl() {
    let obj = omit(this.getObject(), 'frontend_url'),
      compeleteUrlRequired =
        !entityIsAdditionalLink(obj) && this.props.openInNewTab;
    return obj.modifiedUrl || entityUrl(obj, compeleteUrlRequired);
  }

  render() {
    let obj = this.getObject(),
      cardProps = this.cardProps();

    if (['PersonRelation'].includes(obj.__typename))
      return (
        <OsCards
          {...this.props}
          obj={{
            ...cardProps.obj.related_person,
            relation: cardProps.obj.relation || cardProps.obj.logical_relation,
            notify: cardProps.obj.notify,
            person_connection_id: cardProps.obj.id,
          }}
        />
      );

    if (this.isLinkRequired()) {
      return (
        <a
          href={this.getObjectUrl()}
          onClick={(e) => e.preventDefault()}
          className={this.containerClasses().generic}
          ref={(container) => (this.container = container)}
          data-card-id={`${obj.__typename}:${obj.id}`}>
          {this.renderCard()}
        </a>
      );
    } else {
      return (
        <div
          className={this.containerClasses().generic}
          ref={(container) => (this.container = container)}
          data-card-id={`${obj.__typename}:${obj.id}`}>
          {this.renderCard()}
        </div>
      );
    }
  }
}

OsCards.contextType = CARD_ACTION_CONTEXT;
OsCards.defaultProps = {
  notifyParentOnDropdownToggle: () => {},
  viewType: 'Card',
};
OsCards = connect(
  ({ currentUser, device, lectureView, spaceLinks }) => ({
    currentUser,
    device,
    lectureView,
    spaceLinks,
  }),
  {
    authModal,
    openInfoModal,
    openUpgradeModal,
    openPlayerModal,
    setActiveResource,
    viewObject,
  },
)(OsCards);
OsCards = withRouter(OsCards);
export default OsCards;
