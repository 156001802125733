import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withApollo } from '@apollo/client/react/hoc';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import scrollToElement from 'scroll-to-element';

import ActivityBar from 'app/components/FeedView/ActivityBar';
import AddTo from 'app/components/Shared/AddTo';
import Avatar from 'app/components/Shared/Avatar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CommentProcessIcon from 'app/components/Shared/CommentProcessIcon';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import DeleteObject from 'app/components/Shared/DeleteObject';
import EntityEngagementFooter from 'app/components/Shared/EntityEngagementFooter';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import OsGrid from 'app/components/OsGrid';
import OsLink from 'app/components/OsLink';
import ParseText from 'app/components/ParseText';
import Pin from 'app/components/Shared/Pin';
import RecordsPager from 'app/components/RecordsPager';
import SeenByIcon from 'app/components/Comments/SeenByIcon';
import Share from 'app/components/Shared/Share';
import UserContentDisplay from 'app/components/Shared/UserContentDisplay';
import WriteComment from './WriteComment';
import StoreUpdater from 'app/services/StoreUpdater';
import ActionCable from 'app/actioncable/actioncable';
import { openTaskModalWithEntity } from 'app/actions/taskModal';

import { loader as queryLoader } from 'graphql.macro';
import { RecordsPagerContext } from 'app/components/RecordsPager';
import { RecordsViewContext } from 'app/components/RecordsView';

import {
  avatarAttributes,
  entityUrl,
  getRemainingEditTime,
  sortFilesAndGetAttachment,
} from 'app/utils/entitiesHelper';
import { timestamp, timestampOnHover } from 'app/utils/timeHelper';
import { isCareSpace, isPartnerSpace, isSubSpace } from 'app/utils/spaceHelper';
import { keyboardUIAdditionalBarSupported } from 'app/utils/deviceHelper';
import { getCommentOffset, isInViewport } from 'app/utils/domHelper';
import { first, lowerCase, map, reverse } from 'app/utils/osLodash';
import { isInteralTeamComment } from 'app/utils/commentHelper';
import {
  getCareAvatarIconProps,
  getRelationName,
} from 'app/utils/spaces/careHelper';
import {
  isCareWorkspaceView,
  isWorkspaceSpace,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';

import { REPLIES_BATCH_SIZE } from 'app/components/CommentSection/constants';
import { flashSuccess } from 'app/actions/flashMessage';
import Tippy from '@tippyjs/react';
import MarkRead from 'app/components/Inbox/MarkRead';

const RELATED_TASKS = queryLoader(
  'app/graphql/queries/Task/RelatedTaskWidget.gql',
);

// An individual Comment
class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editComment: false,
      keyToggler: true,
      metaParentCommentId: props.metaParentCommentId,
      replyingToComment: this.isReply() ? null : props.comment,
      threadOpen: true,
      anyEditOpen: false,
      isProcessed: this.props.processed,
    };
  }

  componentDidMount() {
    let remainingEditTime = getRemainingEditTime(
      this.props.comment.created_at,
      this.props.systemConfig,
    );
    this.setState(
      { editLinkValid: remainingEditTime > 0 },
      this.handleCommentState,
    );
    this.editLinkValidTimeout = setTimeout(
      this.editCommentInvalid,
      remainingEditTime,
    );
    this.bindScrollToMarkCommentAsViewed();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.scrollToCommentId !== this.props.scrollToCommentId &&
      this.props.scrollToCommentId
    )
      this.handleCommentState();

    if (
      prevProps.editCommentId !== this.props.editCommentId &&
      this.props.editCommentId
    )
      this.handleCommentState();

    if (
      prevProps.replyToCommentId !== this.props.replyToCommentId &&
      this.props.replyToCommentId
    )
      this.handleCommentState();
  }

  componentWillUnmount() {
    clearInterval(this.editLinkValidTimeout);
    if (this.isSeenByIconVisible())
      document.removeEventListener(
        'scroll',
        this.handleScrollToMarkCommentAsViewed,
        false,
      );
  }

  bindScrollToMarkCommentAsViewed() {
    if (this.isSeenByIconVisible())
      document.addEventListener(
        'scroll',
        this.handleScrollToMarkCommentAsViewed,
      );
  }

  notAlreadyViewed() {
    return (
      !this.alreadyMarkedAsViewed &&
      !this.props.comment.viewed_by.some(
        (data) => data.user.id === this.props.currentUser.graph?.id,
      )
    );
  }

  handleScrollToMarkCommentAsViewed = () => {
    if (
      this.seenContainerRef &&
      isInViewport(this.seenContainerRef) &&
      this.notAlreadyViewed() &&
      ActionCable?.notifications &&
      this.props.canView
    ) {
      this.alreadyMarkedAsViewed = true;
      ActionCable?.notifications?.viewComment(this.props.comment.id);
    }
  };

  editCommentInvalid = () => {
    this.setState({ editLinkValid: false });
  };

  isReply() {
    return !!this.props.comment.parent_id;
  }

  isCurrentUserComment() {
    return +this.props.currentUser.graph?.id === +this.props.comment.author.id;
  }

  showMarkAsRead() {
    return this.props.showMarkAsRead;
  }

  openReplyFieldIfRequired() {
    this.shouldReply() && this.onReplyLinkClick();
  }

  openInEditStateIfRequired() {
    if (this.isEditActionAvailable() && this.shouldEdit()) {
      this.toggleEdit();
      this.props.setEditCommentId(null);
    }
  }

  onReplyLinkClick = () => {
    let { comment, entity, openParentReply } = this.props;
    if (this.isFeedView() && !entity.can_user_comment) {
      this.props.navigate(
        entityUrl(entity) + (entity.__typename === 'Case' ? '#insights' : ''),
      );
    } else {
      this.isReply()
        ? openParentReply(comment)
        : this.focusToReplyContainer(comment);
    }
  };

  isFeedView() {
    return this.props.source === 'feeds-view';
  }

  focusToReplyContainer(comment) {
    if (this.replyComponentRef) {
      this.replyComponentRef.setInputActive(comment);
    }
  }

  openReply = (replyingToComment) => {
    this.replyComponentRef.setInputActive(replyingToComment);
    this.setState(
      { editComment: false, replyingToComment },
      this.scrollToNewReplyContainer,
    );
  };

  closeReply = () => {
    this.setState({ editComment: false, replyingToComment: null });
  };

  openCommentThreadForcefully = () => {
    this.setState({ threadOpen: true, threadOpenForcefully: true });
  };

  openCommentThread = () => {
    this.setState({
      threadOpen: !this.state.threadOpen,
      cacheResultNotRequired: true,
    });
  };

  closeCommentThread = () => {
    this.setState({
      threadOpen: false,
      threadOpenForcefully: false,
      metaParentCommentId: null,
    });
  };

  toggleEdit = () => {
    this.setState({ editComment: !this.state.editComment });
  };

  isMetaParentComment() {
    return +this.props.comment.id === +this.state.metaParentCommentId;
  }

  isCommentAroundId() {
    return +this.props.comment.id === +this.props.aroundId;
  }

  shouldOpenThread() {
    return this.isMetaParentComment() && !this.state.threadOpen;
  }

  shouldReply() {
    return +this.props.comment.id === +this.props.replyToCommentId;
  }

  shouldEdit() {
    return +this.props.comment.id === +this.props.editCommentId;
  }

  shouldScroll() {
    return +this.props.comment.id === +this.props.scrollToCommentId;
  }

  isEditActionAvailable() {
    return (
      this.isCurrentUserComment() &&
      !this.state.editComment &&
      this.state.editLinkValid
    );
  }

  isPostFeedCommentView() {
    return this.isCommentable('Pulse') && this.isFeedView() && !this.isReply();
  }

  actionsDropdownRequired() {
    return !this.state.editComment;
  }

  aroundId() {
    return this.isMetaParentComment() && !this.isCommentAroundId()
      ? this.props.aroundId
      : null;
  }

  handleCommentState() {
    if (this.shouldEdit()) {
      this.scrollToComment();
      this.openInEditStateIfRequired();
    } else if (this.shouldScroll()) {
      this.scrollToComment();
      this.openReplyFieldIfRequired();
    } else if (this.shouldOpenThread()) {
      this.scrollToComment();
      this.openCommentThread();
    }
  }

  scrollToComment() {
    let commentElement = document.getElementById(
      'comment-' + this.props.comment.id,
    );
    this.shouldScroll() && this.props.setScrollToCommentId(null);
    this.scrollTo(commentElement);
  }

  getOffset() {
    return getCommentOffset(this.props.commentableType, this.props.device);
  }

  scrollTo(element) {
    if (element && !isInViewport(element)) {
      element.scrollIntoView();
      scrollToElement(element, {
        ease: 'linear',
        offset: this.getOffset(),
        duration: 1,
      });
    }
  }

  repliesCount() {
    return this.isReply() ? 0 : this.props.comment.replies_count;
  }

  refetchReplies = (comment) => {
    if (this.state.threadOpen) {
      if (this.props.comment.replies_count === 0)
        StoreUpdater.addAdditionalRepliesInComment(this.props.comment, comment);
      this.setState({ metaParentCommentId: null });
    } else {
      comment.id && this.props.setScrollToCommentId(comment.id);
      this.openCommentThreadForcefully();
    }
  };

  renderEdit() {
    return (
      <OsBtn
        key='edit'
        name='BtnIcon'
        text='Edit Post'
        extraClass='list-button'
        icon='edit'
        onClick={this.toggleEdit}
        associatedEntity={this.props.comment}
      />
    );
  }

  scrollToNewReplyContainer() {
    if (this.props.device.mobileDevice || this.props.device.ipad) {
      window.setTimeout(() => {
        if (
          this.refs.newReplyContainer &&
          !isInViewport(this.refs.newReplyContainer)
        ) {
          this.refs.newReplyContainer.scrollIntoView({ block: 'end' });

          let offset = this.props.device.mobileDevice ? 20 : 60;
          keyboardUIAdditionalBarSupported() && window.scrollBy(0, offset);
        }
      }, 300);
    }
  }

  renderEditOrComment() {
    let { comment, entity } = this.props;
    if (this.state.editComment) {
      return (
        <div className='comment-reply-container br-none'>
          <WriteComment
            {...comment}
            {...this.getCommonWriteCommentAttributes()}
            parentId={comment.parent_id}
            closeEdit={this.toggleEdit}
            closeReply={this.toggleEdit}
            editComment={true}
            files={sortFilesAndGetAttachment(comment.files)}
            replies_count={this.repliesCount()}
            activityId={this.props.activityId}
          />
        </div>
      );
    } else {
      return (
        <div className='comment-text'>
          {this.isTypeOfQuestion() && (
            <OrthoIcon name='questions' dataHoverNotRequired={true} />
          )}
          <ParseText
            parentEntity={entity}
            obj={comment}
            className='comment-text-helper'
          />
        </div>
      );
    }
  }

  initialReplies() {
    return this.props.comment.additional_replies
      ? reverse([...this.props.comment.additional_replies])
      : null;
  }

  getAdditionalRepliesProps() {
    if (this.aroundId() || this.state.cacheResultNotRequired) {
      return {
        aroundId: this.aroundId(),
      };
    } else {
      const initialReplies = this.initialReplies();
      return {
        initialRecords: initialReplies
          ? initialReplies.map((reply) => ({ ...reply, public_feed: null }))
          : [],
        parentEntity: this.props.comment,
      };
    }
  }

  renderReplies() {
    return (
      <CSSTransition
        in={true}
        classNames='replies-fade'
        timeout={{ enter: 500, exit: 200 }}
        unmountOnExit>
        <RecordsPager
          {...this.getAdditionalRepliesProps()}
          closeCommentThread={this.closeCommentThread}
          commentableEntityOwner={this.props.commentableEntityOwner}
          commentableId={this.props.commentableId}
          commentableType={this.props.commentableType}
          editCommentId={this.props.editCommentId}
          entity={this.props.entity}
          hideReplyButton={this.state.replying}
          infiniteScroll={false}
          key={this.state.keyToggler}
          limit={REPLIES_BATCH_SIZE}
          openParentReply={this.openReply}
          queryType='COMMENTS'
          readOnly={this.props.readOnly}
          recordId={this.props.comment.id}
          recordType='Comment'
          replyToCommentId={this.props.replyToCommentId}
          scrollToCommentId={this.props.scrollToCommentId}
          setEditCommentId={this.props.setEditCommentId}
          setScrollToCommentId={this.props.setScrollToCommentId}
          type='Replies'
          fetchPolicy={'cache-and-network'}
          parentEntity={this.props.comment}
          withRecord={true}
          activityId={this.props.activityId}
        />
      </CSSTransition>
    );
  }

  isSeenByIconVisible() {
    let space = this.props.entity;
    return (
      isWorkspaceSpace(space) ||
      (isSubSpace(space) && isWorkspaceView()) ||
      isCareSpace(space) ||
      isPartnerSpace(space)
    );
  }

  isTypeOfQuestion() {
    return this.props.comment.type === 'Question';
  }

  getCommentTypeValue() {
    return lowerCase(this.props.comment.type) === 'discussion'
      ? 'comment'
      : lowerCase(this.props.comment.type);
  }

  renderTimestamp() {
    return timestamp(this.props.comment.created_at);
  }

  renderList(objects) {
    return objects.map(this.renderCard);
  }

  cardSize() {
    return 'extra-small';
  }

  renderCard = (object) => {
    let attachments = map(this.props.comment.files, 'file_asset'),
      gridIdentifer = this.isCommentable('Pulse')
        ? 'PulseDiscussionCard:Cardcols'
        : 'DiscussionCard:Cardcols';

    return (
      <OsGrid
        identifier={gridIdentifer}
        key={`${object.__typename}:${object.id}`}>
        <OsCards
          size={this.cardSize()}
          obj={object}
          attachments={attachments}
          sliderPreview={true}
          source='Comment'
        />
      </OsGrid>
    );
  };

  renderObjects() {
    let { links, files, author } = this.props.comment;

    if ((links.length > 0 || files.length > 0) && !this.state.editComment)
      return (
        <div className='row comment-attachment-block'>
          {this.renderList(files.map((f) => ({ ...f.file_asset, author })))}
          {this.renderList(links)}
        </div>
      );
  }

  renderAddTo(data = {}) {
    let extraProps = this.isFeedView() ? { iconName: 'spaces-fill' } : {};
    return (
      <AddTo
        obj={this.props.comment}
        extraClass='list-button'
        {...extraProps}
      />
    );
  }

  renderShareButton() {
    return (
      <Share
        key='share'
        iconName='share-fill'
        object={this.props.comment}
        label='Share Post'
        extraClass='list-button'
      />
    );
  }

  renderDeleteButton() {
    let source = {
      id: this.props.commentableId || this.props.entity.id,
      type: this.props.commentableType || this.props.entity.__typename,
      comments_count: this.props.entity.comments_count,
    };
    return (
      <DeleteObject
        key='delete'
        object={this.props.comment}
        label='Delete Post'
        extraClass='list-button'
        source={source}
        sortQuery={this.props.sortQuery}
        listType={this.props.source}
        beforeId={this.props.beforeId}
        activityId={this.props.activityId}
      />
    );
  }

  isCarespaceFeedView() {
    return this.props.feedType === 'carespace-feed-view';
  }

  updateContextualWidgetList = (task) => {
    if (this.isCarespaceFeedView()) {
      let entity = `Space::Base-${this.props.comment.commentable_id}`;
      StoreUpdater.addRecord(
        task,
        {
          page: 0,
          perPage: 10,
          sortQuery: 'due_date_asc',
          type: 'EntityRelatedTasks',
          additional_filters: JSON.stringify({ entity }),
        },
        {
          query: RELATED_TASKS,
          isReverse: true,
        },
      );
    }
  };

  renderCreateTasksButton() {
    return (
      <OsBtn
        key='taskCreate'
        name='BtnIcon'
        text='Create Task'
        extraClass='list-button'
        icon='edit'
        associatedEntity={this.props.comment}
        onClick={() => this.openEmptyTaskModal()}
      />
    );
  }

  renderCopyLinkButton() {
    return (
      <CopyToClipboard
        text={entityUrl(this.props.comment, true, {
          workspaceIdentifier: this.props.workspace.identifier,
          commentableId: this.props.commentableId,
        })}>
        <OsBtn
          key='copyLink'
          name='BtnIcon'
          text='Copy Link'
          extraClass='list-button'
          onClick={() => this.props.flashSuccess('Copied to Clipboard', false)}
          icon='document-empty'
        />
      </CopyToClipboard>
    );
  }

  openEmptyTaskModal = () => {
    this.props.openTaskModalWithEntity(
      this.props.comment,
      this.updateContextualWidgetList,
      true,
    );
  };

  renderActionDropDown() {
    let disabled = this.props.readOnly,
      extraClass = disabled ? ' osbtn-disabled ' : '',
      isWorkspace = this.props.isWorkspace;

    return (
      <div className=' ms-auto fs-12 dot-with-select '>
        {isWorkspace && (
          <OrthoIcon
            name={'task-light'}
            dataHoverNotRequired={true}
            onClick={this.openEmptyTaskModal}
          />
        )}
        {isWorkspace &&
          (isCareSpace(this.props.entity) ||
            isPartnerSpace(this.props.entity)) && (
            <CommentProcessIcon comment={this.props.comment} />
          )}
        <CustomDropdown
          disabled={disabled}
          name='discussion_actions'
          className='comment-more position-relative'
          extraClass={extraClass}>
          {this.renderPinnedInformation()}
          {this.isEditActionAvailable() && this.renderEdit()}
          {this.showMarkAsRead() && (
            <MarkRead
              entityId={this.props.comment.nice_id}
              entityType={this.props.comment.__typename}
              type={this.props.inboxType}
              entity={this.props.markReadEntity || this.props.entity}
              queryType={this.props.listType}
            />
          )}
          {this.renderAddTo()}
          {/* {this.renderShareButton()} */}
          {this.isCurrentUserComment() && this.renderDeleteButton()}
          {isWorkspace && this.renderCreateTasksButton()}
          {this.renderCopyLinkButton()}
        </CustomDropdown>
      </div>
    );
  }

  getUserAvatarAttributes(user) {
    return avatarAttributes(
      user,
      getCareAvatarIconProps(this.props.entity, user, { bypassColor: true }),
    );
  }

  renderAvatarDetails() {
    let user = this.props.currentUser.graph,
      avatarSize = this.props.device.mobileDevice ? 32 : 40;

    return (
      <Link className='comments-primary-avatar' to={entityUrl(user)}>
        <Avatar
          className={`align-top avatar avatar-${avatarSize}`}
          {...this.getUserAvatarAttributes(user)}
        />
      </Link>
    );
  }

  isDiscussionTab() {
    return this.props.match.params.filter === 'discussions';
  }

  renderAuthorName = ({ to, name }) => {
    if (to) {
      return (
        <OsLink
          to={to}
          className='feed-author-info hover-underline user-link'
          text={name}
        />
      );
    } else {
      return name;
    }
  };

  renderAuthorDetailRow() {
    const { author, secured } = this.props.comment,
      authorUrl = entityUrl(author),
      to = true || this.props.isWorkspace ? authorUrl : null;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='user-info-row d-flex align-items-center w-100'>
          <Link to={to} className='avatar-listing'>
            <Avatar
              className={`avatar avatar-${this.getAvatarSize()} comments-secondary-avatar`}
              {...this.getUserAvatarAttributes(author)}
            />
          </Link>
          <div className='user-name-block'>
            <span className='user-with-relation'>
              {this.renderAuthorName({ to, name: author.full_name })}
              {this.getAuthorRelationIfRequired()}
            </span>
            <div
              className='time-with-seenlist secured-post'
              ref={(div) => (this.seenContainerRef = div)}>
              <Tippy
                content={secured ? 'Secure' : 'Delivered full message'}
                placement='bottom'>
                <span className='post-visibility'>
                  <OrthoIcon
                    name={secured ? 'locked' : 'passwordShow'}
                    dataHoverNotRequired={true}
                  />
                </span>
              </Tippy>
              {/* <div className='time-with-seenlist'> */}
              <span className='copy-tooltip'>
                {this.state.linkCopied && (
                  <span className='tooltip-copy'>Copied to clipboard!</span>
                )}
                <CopyToClipboard
                  text={entityUrl(this.props.comment, true, {
                    workspaceIdentifier: this.props.workspace.identifier,
                    commentableId: this.props.commentableId,
                  })}
                  onCopy={this.onLinkCopy}>
                  <span
                    className='media-comment-time'
                    title={timestampOnHover(this.props.comment.created_at)}>
                    {this.renderTimestamp()}
                  </span>
                </CopyToClipboard>
                {!this.state.linkCopied && (
                  <span className='tooltip-copy hover-tooltip'>
                    Click to copy
                  </span>
                )}
              </span>
              {this.renderSeenByIcon()}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {this.actionsDropdownRequired() && this.renderActionDropDown()}
        </div>
      </div>
    );
  }

  getCommonWriteCommentAttributes() {
    return {
      entity: this.props.entity,
      setScrollToCommentId: this.props.setScrollToCommentId,
      commentableId: this.props.commentableId,
      commentableType: this.props.commentableType,
      commentableEntityOwner: this.props.commentableEntityOwner,
    };
  }

  setRef = (replyComponentRef) => {
    this.replyComponentRef = replyComponentRef;
  };

  renderReplyContainer() {
    let className = 'comment-reply-container',
      replyingToComment = this.state.replyingToComment,
      refetchOnCreateRequired = this.repliesCount() === 0;

    className += !refetchOnCreateRequired ? '' : ' br-top ps-0';
    return (
      <div className={className} ref='newReplyContainer'>
        <div className='media'>
          {this.renderAvatarDetails()}
          <div id={'editor-box'} className='media-body'>
            <WriteComment
              {...this.getCommonWriteCommentAttributes()}
              aroundId={this.aroundId()}
              author={replyingToComment && replyingToComment.author}
              closeReply={this.closeReply}
              comment={this.props.comment}
              parentId={this.props.comment.id}
              replyingToCommentId={replyingToComment && replyingToComment.id}
              threadOpen={this.state.threadOpen}
              refetchOnCreate={refetchOnCreateRequired && this.refetchReplies}
              newComment={true}
              setRef={this.setRef}
              source={this.props.source}
              mentionees={this.props.comment.mentionees}
              isInternalNote={isInteralTeamComment(this.props.comment)}
              replies_count={this.repliesCount()}
              activityId={this.props.activityId}
              secured={this.props.comment.secured}
            />
          </div>
        </div>
      </div>
    );
  }

  renderPinnedInformation() {
    let { can_pin_comments, pinnable } = this.props.comment;
    if (can_pin_comments && pinnable)
      return (
        <Pin
          obj={this.props.comment}
          extraClass='list-button'
          containerRef={this.commentContainer}
          offset={this.getOffset()}
          refetchOnSuccess={this.props.refetchOnPin}
        />
      );
  }

  isCommentable(type) {
    return this.props.commentableType === type;
  }

  renderContent() {
    if (!this.state.editComment) {
      return (
        <UserContentDisplay
          parentEntity={this.props.entity}
          object={this.props.comment}
          source='comments-section'
        />
      );
    } else {
      return (
        <>
          {this.renderEditOrComment()}
          {this.renderObjects()}
        </>
      );
    }
  }

  getAvatarSize() {
    if (!this.isReply()) {
      return this.props.device.mobileDevice ? 32 : 40;
    } else {
      return this.props.device.mobileDevice ? 40 : 40;
    }
  }

  onLinkCopy = () => {
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 1500);
  };

  validAddtionalReplies() {
    return this.props.comment.additional_replies.filter(
      (obj) => obj.__typename === 'Comment',
    );
  }

  isActivityBarRequired() {
    return (
      this.props.comment.public_feed?.effect_activity &&
      this.validAddtionalReplies().length > 0
    );
  }

  activityBarInfo() {
    const { comment } = this.props;
    return {
      effectInterpolationDataInfo: {
        object: first(comment.additional_replies),
      },
      feed: comment.public_feed,
      interpolationDataInfo: {},
      interpolationOptions: {
        extraLinkClass: 'white-link',
      },
      extraClass: this.isProcessedClassRequired() ? ' processed' : '',
    };
  }

  getAuthorRelationIfRequired() {
    let { entity } = this.props;
    if (isCareSpace(entity) && isWorkspaceView()) {
      let { comment } = this.props,
        authorRelationName = getRelationName(comment.author, entity);

      if (authorRelationName) {
        return (
          <span className='relation-text pl-2'>({authorRelationName}) </span>
        );
      }
    }
  }

  renderActivityBar() {
    let isInternalNote = isInteralTeamComment(this.props.comment);
    let extraClass;
    if (isInternalNote) {
      extraClass = 'internal-note';
    }

    if (this.isActivityBarRequired()) {
      return (
        <ActivityBar
          {...this.activityBarInfo()}
          isInternalNote={!isCareWorkspaceView() && isInternalNote}
          extraClass={!isCareWorkspaceView() && extraClass}
        />
      );
    } else if (!isCareWorkspaceView() && isInternalNote) {
      return (
        <div className={`group-by-space-name only-internal-note ${extraClass}`}>
          <span>Clinic Team Only</span>
        </div>
      );
    }
  }

  renderSeenByIcon() {
    if (this.isSeenByIconVisible()) {
      const { id, viewed_by, view_count } = this.props.comment;
      return (
        <SeenByIcon
          id={id}
          viewedBy={viewed_by}
          viewCount={view_count}
          parentEntity={this.props.entity}
        />
      );
    }
  }

  renderPinnedIcon() {
    return (
      <div className='pinned-post-block'>
        <OrthoIcon name='pin' dataHoverNotRequired={true} />
        <span>Pinned Post</span>
      </div>
    );
  }

  renderCommentDetails() {
    const isPinned = this.props.comment.pinned && !this.isFeedView(),
      additionalReplies = this.props.comment.additional_replies || [];
    return (
      <>
        {isPinned && this.renderPinnedIcon()}
        {this.renderAuthorDetailRow()}
        <div className='media-body'>
          {this.renderContent()}
          {this.renderFooter()}
          <div className='discussion-thread-section'>
            <div
              className='comment-reply-list'
              key={`Reply-${additionalReplies.length}-${additionalReplies[0]?.id}`}>
              {this.renderReplies()}
              {!this.isReply() &&
                !this.props.readOnly &&
                this.renderReplyContainer()}
            </div>
          </div>
        </div>
      </>
    );
  }

  onCommentIconClick = () => {
    if (this.isFeedView()) {
      this.props.onCommentIconClick();
    } else {
      this.openCommentThread();
    }
  };

  renderFooter() {
    return (
      !this.state.editComment && (
        <EntityEngagementFooter
          obj={this.props.comment}
          onCommentIconClick={this.onCommentIconClick}
          disabled={this.props.readOnly}
          onReplyLinkClick={this.onReplyLinkClick}
          onRepliesLinkClick={this.openCommentThread}
          postFeedCommentView={this.isPostFeedCommentView()}
          source='comment-section'
        />
      )
    );
  }

  isProcessedClassRequired() {
    return (
      isWorkspaceView() &&
      this.props.comment.processed &&
      !this.props.comment.parent_id
    );
  }

  render() {
    let className = 'media comment ';
    className += this.props.comment.pinned ? 'pinned-post' : '';
    className += this.isProcessedClassRequired() ? ' processed' : '';
    return (
      <>
        <li
          className={className}
          id={`comment-${this.props.comment.id}`}
          ref={(el) => (this.commentContainer = el)}>
          {this.renderCommentDetails()}
        </li>
      </>
    );
  }
}

Comment = withApollo(Comment);
Comment = withRouter(Comment);
Comment = connect(
  ({ currentUser, systemConfig, device, source, workspace }) => ({
    currentUser,
    systemConfig,
    device,
    isWorkspace: source.isWorkspace,
    workspace,
  }),
  { openTaskModalWithEntity, flashSuccess },
)(Comment);
Comment.defaultProps = {
  setScrollToCommentId: () => {},
};

const ConnectedComment = (props) => {
  const { canView: canViewFromRecordsPager } = useContext(RecordsPagerContext);
  const { canView: canViewFromRecordsView } = useContext(RecordsViewContext);
  return (
    <Comment
      {...props}
      canView={canViewFromRecordsView && canViewFromRecordsPager}
    />
  );
};
export default ConnectedComment;
