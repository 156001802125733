import { isFeaturePermitted } from 'app/utils/userHelper';
import {
  canSpaceBeArchived,
  canSpaceBeUnArchived,
  isDynamicTemplate,
  isFolderSpace,
  isNewSubSpaceAllowed,
  isOpenSpace,
  isDeletableSpace,
  isSpaceArchived,
  isCompanySpace,
  isCareSpace,
} from 'app/utils/spaceHelper';
import store from 'app/store';
import {
  isCareWorkspaceView,
  isWorkspaceAuthorOrEditor,
} from 'app/utils/Workspace/generalHelper';
import { isCurrentUserSystemAdmin } from 'app/utils/userHelper';

const SPACE_SHARE_LINK_FEATURE_IDENTIFIER = 'space_share_link_identifier';

export function isCommunicationFeatureEnabled() {
  return !isCareWorkspaceView();
}

function isOwnedByUser(user, space) {
  return user && +space.user.id === +user.id;
}

export function isNoteRequired(space) {
  return !isCareSpace(space);
}

export function isShareRequired(space) {
  return isOpenSpace(space) && !isSpaceArchived(space);
}

export function isEditImageRequired(space) {
  return space.is_author_or_editor && !isCareSpace(space);
}

export function isEditSpaceRequired(user, space) {
  return (
    isOwnedByUser(user, space) && !isCareSpace(space) && !isFolderSpace(space)
  );
}

export function isEditInformationRequired(space) {
  return !isCareWorkspaceView() && space.data.is_author_or_editor;
}

export function isAddToSpaceRequired(obj) {
  return (
    isWorkspaceAuthorOrEditor() &&
    !(isCareWorkspaceView() || ['Comment', 'Board'].includes(obj.__typename))
  );
}

export function isDeleteRequired(user, space) {
  return (
    isOwnedByUser(user, space) &&
    isDeletableSpace(space) &&
    isSpaceArchived(space)
  );
}

export function isArchiveSpaceRequired(space) {
  return canSpaceBeArchived(space) || canSpaceBeUnArchived(space);
}

export function isFollowRequired(space) {
  return space.is_author_or_collaborator && !isFolderSpace(space);
}

export function isNewSubspaceRequired(space) {
  return space.is_author_or_editor && isNewSubSpaceAllowed(space);
}

export function isSearchPageRequired() {
  return true;
}

export function isSearchFeatureEnabled() {
  return false;
  // return !isCareWorkspaceView()
}

export function isMobileExpandedNavTabEnabled(entity) {
  return (
    store.getState().device.mobileDevice &&
    (isFolderSpace(entity) || isDynamicTemplate(entity))
  );
}

export function renameSpaceFormRequired(space) {
  return space.is_author_or_editor && isFolderSpace(space);
}

export function getRenameSpaceFormUniqueIdentifier(space) {
  return `rename-${space.nice_id}`;
}

export function isWorkspaceSettingViewAllowed(space) {
  return space?.is_author_or_editor || isCurrentUserSystemAdmin();
}
