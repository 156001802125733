import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Link } from 'react-router-dom';
import { Accordion, Modal } from 'react-bootstrap';

import AbstractPersonCard from './AbstractCard';
import AssociateLabelDropdown from 'app/components/Shared/AssociateLabelDropdown';
import Avatar from 'app/components/Shared/Avatar';
import CareSpacePartyShareButton from 'app/components/Shared/CareSpace/ContactPartyShareButton';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import PersonRelatedCareSpaces from 'app/components/Shared/PersonRelatedCareSpaces';
import RecordsView from 'app/components/RecordsView';
import CareSpaceDisableAccessModal from 'app/components/AllModalsContainer/CareSpace/DisableAccessModal';

import { pluralize } from 'app/utils/generalHelper';
import {
  avatarAttributes,
  entityUrl,
  relativeEntityUrl,
} from 'app/utils/entitiesHelper';
import {
  getCareAvatarIconProps,
  isDeclined,
  isSharedWithParty,
  isSharedWithAnyParty,
  isSharedWithUserOrGuardian,
  isUserAndGuardianDeclined,
  isUserAuthenticated,
  isUserOrGuardianAuthenticated,
} from 'app/utils/spaces/careHelper';
import { openPatientModal } from 'app/actions/patientModal';
import {
  addContactParty,
  editContactParty,
} from 'app/actions/contactPartyModal';
import { timeWithFormat } from 'app/utils/timeHelper';
import { humanize } from 'app/utils/stringHelper';
import { openTaskModalWithEntity } from 'app/actions/taskModal';

import { CARD_ACTION_CONTEXT } from 'app/components/OsCards/AbstractCard';
import { isWorkspaceAuthorOrEditor } from 'app/utils/Workspace/generalHelper';
import CopyToClipboard from 'react-copy-to-clipboard';
import ImageEdit from 'app/components/AccountView/imageEdit';
import CareSpaceDisableAccessBtn from 'app/components/Shared/CareSpace/DisableAccessBtn';
import { messageHelper } from 'app/utils/tippyMessage';

class PersonTableRow extends AbstractPersonCard {
  constructor(props) {
    super(props);
    this.state = {
      contactsOpen: props.contactsOpen,
      showModalEdit: false,
    };
  }

  isPatientCircleRequired() {
    return !this.contactsView();
  }

  renderContactSmallCircle(space, user, person) {
    if (space) {
      if (isSharedWithParty(space, user, person)) {
        if (isUserAuthenticated(space, user)) {
          return (
            <OrthoIcon
              name='checkmark-dot'
              defaultClass='small-status-icon green'
              dataHoverNotRequired={true}
            />
          );
        } else if (isDeclined(space, user)) {
          return (
            <OrthoIcon
              name='checkmark-dot'
              defaultClass='small-status-icon red'
              dataHoverNotRequired={true}
            />
          );
        } else {
          return (
            <OrthoIcon
              name='checkmark-dot'
              defaultClass='small-status-icon'
              dataHoverNotRequired={true}
            />
          );
        }
      } else {
        return (
          <OrthoIcon
            name='checkmark-round-default'
            defaultClass='small-status-icon'
            dataHoverNotRequired={true}
          />
        );
      }
    }
  }

  renderBigCircle(space) {
    if (space) {
      let { obj } = this.props,
        user = { id: obj.user_id };
      if (this.props?.isListItem) {
        if (isUserOrGuardianAuthenticated(space, user)) {
          return this.renderAccessedIcon();
        } else if (
          isSharedWithUserOrGuardian(space) ||
          isSharedWithAnyParty(space, user, obj)
        ) {
          return this.renderSharedButNotAccessedIcon();
        } else if (isUserAndGuardianDeclined(space)) {
          return this.renderErrorRedIcon();
        } else {
          return this.renderNotSharedIcon();
        }
      } else {
        if (isSharedWithParty(space, user, obj)) {
          if (isUserAuthenticated(space, user)) {
            return this.renderAccessedIcon();
          } else if (isDeclined(space, user)) {
            return this.renderErrorRedIcon();
          } else {
            return this.renderSharedButNotAccessedIcon();
          }
        } else {
          return this.renderNotSharedIcon();
        }
      }
    }
  }

  renderStatusCircle() {
    if (this.contactsView()) {
      return this.renderBigCircle(this.context.sourceCareSpace);
    } else {
      return (
        <>
          {this.isPatientCircleRequired() && this.renderBigCircle(this.space())}
        </>
      );
    }
  }

  openEditModal = () => {
    if (this.contactsView() && !this.space()) {
      this.props.editContactParty({
        space: this.context.sourceCareSpace,
        contactParty: this.props.obj,
      });
    } else {
      this.props.openPatientModal(this.space().nice_id, {
        source: this.source(),
        patient: this.careSpace().owner.patient,
      });
    }
  };

  openCareSpace = () => {
    this.props.navigate(entityUrl(this.space()));
  };

  toggleContactsAccordion = () => {
    this.setState({ contactsOpen: !this.state.contactsOpen });
  };

  renderEditInformation() {
    // ToDo: Create edit guardian separate form.
    if (
      !this.props.device.mobileDevice &&
      isWorkspaceAuthorOrEditor() &&
      (this.space() || this.context.sourceCareSpace) &&
      (!this.isContact() || this.contactsView())
    ) {
      return (
        <OsBtn
          name='BtnIcon'
          text='Edit information'
          icon='edit'
          extraClass='list-button'
          onClick={this.openEditModal}
        />
      );
    }
  }

  toggleRelatedCareSpacesModal = () => {
    this.setState({
      relatedCareSpaceModalOpen: !this.state.relatedCareSpaceModalOpen,
    });
  };

  convertToCareSpace = () => {
    this.props.openPatientModal(null, {
      source: this.source(),
      newPersonId: this.props.obj.id,
    });
  };

  renderShowSpace() {
    return (
      <OsBtn
        name='BtnIcon'
        text='Show PatientSpace'
        icon='case-person'
        extraClass='list-button'
        onClick={this.openCareSpace}
      />
    );
  }

  renderContactToggleButton() {
    let text = this.state.contactsOpen ? 'Hide Contacts' : 'Show Contacts';
    return (
      <OsBtn
        name='BtnIcon'
        text={text}
        icon='edit'
        extraClass='list-button'
        onClick={this.toggleContactsAccordion}
      />
    );
  }

  convertToCareSpaceButton() {
    if (isWorkspaceAuthorOrEditor() && !this.props.device.mobileDevice)
      return (
        <OsBtn
          name='BtnIcon'
          text='Convert To Patient Space'
          icon='case-person'
          extraClass='list-button'
          onClick={this.convertToCareSpace}
        />
      );
  }

  renderRelatedCareSpacesButton() {
    return (
      <OsBtn
        name='BtnIcon'
        text='See Related Patient Spaces'
        icon='user-fill'
        extraClass='list-button'
        onClick={this.toggleRelatedCareSpacesModal}
      />
    );
  }

  careSpace() {
    return (
      this.context?.sourceCareSpace || {
        ...this.space(),
        owner: {
          __typename: 'Case',
          patient: this.props.obj,
        },
      }
    );
    return (
      this.context?.sourceCareSpace || {
        ...this.space(),
        owner: {
          __typename: 'Case',
          patient: this.props.obj,
        },
      }
    );
  }

  renderAddTaskButton() {
    const careSpace = this.careSpace();
    return (
      <OsBtn
        key='taskCreate'
        name='BtnIcon'
        text='Create Task'
        extraClass='list-button'
        icon='edit'
        associatedEntity={careSpace}
        onClick={() => this.props.openTaskModalWithEntity(careSpace)}
      />
    );
  }

  renderShareOrReshareButton() {
    const careSpace = this.careSpace();
    return (
      <CareSpacePartyShareButton
        space={careSpace}
        contactParty={this.props.obj}
      />
    );
  }

  renderCopyLinkButton() {
    const careSpace = this.careSpace();

    return (
      <CopyToClipboard text={relativeEntityUrl(careSpace, true)}>
        <OsBtn
          key='copyLink'
          name='BtnIcon'
          text='Copy Link'
          extraClass='list-button'
          icon='document-empty'
        />
      </CopyToClipboard>
    );
  }

  openContactParty() {
    this.props.addContactParty({ space: this.careSpace() });
  }

  renderAddContactButton() {
    return (
      <OsBtn
        key='addContact'
        name='BtnIcon'
        text='Add Contact'
        extraClass='list-button'
        icon='add'
        onClick={(e) => this.openContactParty()}
      />
    );
  }

  openModalEdit = () => {
    this.setState({ showModalEdit: true });
  };

  closeModalEdit = () => {
    this.setState({ showModalEdit: false });
  };

  renderEditImageButton() {
    return (
      <OsBtn
        key='editImage'
        name='BtnIcon'
        text='Edit Profile Photo'
        extraClass='list-button'
        icon='edit'
        onClick={this.openModalEdit}
      />
    );
  }

  isDisableAccessModalOpen() {
    return this.props.disableCareSpaceModal.obj.id === this.props.obj.id;
  }

  renderDisableAccessButton() {
    return (
      <CareSpaceDisableAccessBtn
        space={this.careSpace()}
        obj={this.props.obj}
      />
    );
  }

  renderSeparator() {
    return <div className='separator' />;
  }

  renderDropDown() {
    return (
      <CustomDropdown
        name='care_space_action'
        className='card-dropdown-menu care-space-card-dropdown top-18'
        onDropDownToggle={this.props.onDropDownToggle}>
        {this.renderShareOrReshareButton()}
        {!this.isContact() && this.renderAddContactButton()}
        {this.renderEditInformation()}
        {this.renderEditImageButton()}
        {this.renderSeparator()}
        {this.renderAddTaskButton()}
        {!this.isContact() && this.renderCopyLinkButton()}
        {this.renderRelatedCareSpacesButton()}
        {this.isContact() && this.convertToCareSpaceButton()}
        {this.renderDisableAccessButton()}
      </CustomDropdown>
    );
  }

  renderLabels() {
    return (
      <AssociateLabelDropdown entity={this.props.obj} position='right-start' />
    );
  }

  space() {
    return this.props.obj.care_space;
  }

  isContact() {
    return !this.space();
  }

  renderRelatedPersonCount() {
    let count = this.props.obj.person_connections_user_ids?.length,
      className = this.state.contactsOpen ? 'ac-open' : '';
    return (
      <div
        className={`vs-ac-toggle ${className}`}
        onClick={this.toggleContactsAccordion}>
        <span>
          {count > 0
            ? `${count} ${pluralize(count, { singular: 'contact' })}`
            : ''}
        </span>
        {count > 0 && (
          <OrthoIcon
            name={
              this.state.contactsOpen
                ? 'chevron-caret-up'
                : 'chevron-caret-down'
            }
            defaultClass='ms-1'
          />
        )}
      </div>
    );
  }

  renderRelatedPersonCountOrName() {
    if (this.contactsView()) {
      return this.props.obj.relation;
    } else {
      return this.renderRelatedPersonCount();
    }
  }

  getContextProps() {
    return { contactsView: true, sourceCareSpace: this.careSpace() };
  }

  renderDetailsAccordion() {
    if (this.state.contactsOpen) {
      return (
        <Accordion activeKey={true} className='vs-accordion'>
          <CARD_ACTION_CONTEXT.Provider value={this.getContextProps()}>
            <RecordsView
              type='UserResponseParties'
              queryType='VISTIOR_DIRECTORY'
              cardProps={{ size: 'list' }}
              idQuery={this.props.obj.id}
              textQuery={this.space()?.nice_id}
            />
          </CARD_ACTION_CONTEXT.Provider>
        </Accordion>
      );
    }
  }

  contactsView() {
    return this.context?.contactsView;
  }

  source() {
    return 'visitor-directory';
  }

  renderContactLabel() {
    return <div className='contact-text'>Contact</div>;
  }

  tagsClass() {
    return 'badge-sm';
  }

  renderName() {
    let { name, care_space } = this.props.obj;
    let nameClass = 'name-hover';
    nameClass += care_space?.unseen_comments_present ? ' unread' : '';
    if (this.isContact()) {
      return <span>{name}</span>;
    } else {
      return (
        <OsLink
          className={nameClass}
          to={entityUrl(this.space())}
          openInNewTab={false}
          text={name}
        />
      );
    }
  }

  renderAvatarLink(props) {
    const { obj } = this.props;
    if (this.space()) {
      return (
        <Link className='comments-primary-avatar' to={entityUrl(this.space())}>
          <Avatar
            className='avatar avatar-40'
            {...avatarAttributes(obj, props)}
          />
        </Link>
      );
    } else {
      return (
        <Avatar
          className='avatar avatar-40'
          {...avatarAttributes(obj, props)}
        />
      );
    }
  }

  renderInformation() {
    let { obj } = this.props;
    return (
      <div className='cd-list-info'>
        <span className='cd-file-name'>{obj.email}</span>
        <span className='cd-extra-info'>{obj.phone_number}</span>
      </div>
    );
  }

  renderPID() {
    let { obj } = this.props;
    return (
      <div className='cd-list-pid'>{obj.identification_number || 'NA'}</div>
    );
  }

  renderLastActivityDetails() {
    let space = this.space();
    if (space)
      if (this.contactsView()) {
        let { obj } = this.props;

        if (obj.user?.last_active_at) {
          return (
            <span className='cd-file-name' title='Last Active At'>
              {timeWithFormat(obj.user.last_active_at, 'MM/DD/YY')}
            </span>
          );
        } else {
          return <span className='cd-file-name'>Unverified</span>;
        }
      } else if (space.last_activity_performed_at) {
        return (
          <span title={humanize(space.last_activity_type)}>
            <span className='cd-file-name'>
              {timeWithFormat(space.last_activity_performed_at, 'MM/DD/YY')}
            </span>
            <span className='cd-extra-info'>
              {messageHelper(
                space.last_activity_type,
                space.last_activity_performed_by?.name,
              )}
            </span>
          </span>
        );
      }
  }

  render() {
    const { obj } = this.props,
      avatarProps = getCareAvatarIconProps(
        obj.care_space || this.context.sourceCareSpace,
        { id: obj.user_id },
        { isPatientOrGuardian: true },
      );

    return (
      <>
        <div className='visitors-table-row table-row-only' onClick={() => {}}>
          <div
            className='v-tb-block counter'
            style={
              !this.props.device.mobileDevice
                ? { position: 'sticky', left: '0px', background: '#fff' }
                : {}
            }>
            {this.props.index + 1}
          </div>
          <div
            className='v-tb-block name-block'
            style={
              !this.props.device.mobileDevice
                ? { position: 'sticky', left: '40px', background: '#fff' }
                : {}
            }>
            <div className='cd-list-img'>
              {this.renderAvatarLink(avatarProps)}
            </div>
            <div className='cd-list-info'>
              <span className='cd-file-name break-word'>
                {this.renderName()}
                {this.renderTags()}
              </span>
              <span className='cd-extra-info'>{obj.date_of_birth}</span>
            </div>
          </div>
          <div className='v-tb-block cd-pid'>{this.renderPID()}</div>
          <div className='v-tb-block cd-information'>
            {this.renderInformation()}
          </div>

          <div className='v-tb-block cd-status'>
            <div className='status-circle'>{this.renderStatusCircle()}</div>
          </div>

          <div className='v-tb-block cd-contacts'>
            {this.renderRelatedPersonCountOrName()}
          </div>

          <div className='v-tb-block cd-label'>
            {this.isContact() ? this.renderContactLabel() : this.renderLabels()}
          </div>

          <div className='v-tb-block cd-lt-activity'>
            <div className='cd-list-info'>
              {this.renderLastActivityDetails()}
            </div>
          </div>
          <div className='v-tb-block pos-rel cd-btn'>
            {this.renderDropDown()}
          </div>
        </div>
        {this.renderDetailsAccordion()}
        {this.state.relatedCareSpaceModalOpen && (
          <PersonRelatedCareSpaces
            obj={obj}
            onClose={this.toggleRelatedCareSpacesModal}
          />
        )}
        <Modal
          show={this.state.showModalEdit}
          onHide={this.closeModalEdit}
          dialogClassName='modal-xl profile-image-modal'
          animation={false}
          backdropClassName='modal-backdrop-custom'>
          <ImageEdit closeParentModal={this.closeModalEdit} obj={obj} />
        </Modal>
        {this.isDisableAccessModalOpen() && (
          <CareSpaceDisableAccessModal
            space={this.careSpace()}
            obj={this.props.obj}
          />
        )}
      </>
    );
  }
}

PersonTableRow = withRouter(PersonTableRow);
PersonTableRow = connect(
  ({ device, disableCareSpaceModal }) => ({ device, disableCareSpaceModal }),
  {
    editContactParty,
    openPatientModal,
    openTaskModalWithEntity,
    addContactParty,
  },
)(PersonTableRow);
export default PersonTableRow;
